import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import CustomInput from './CustomInput';
import { ReactComponent as Doctor } from '../../../../../assets/img/pharmacy/DOCTOR.svg';
import { ReactComponent as MedicalCommission } from '../../../../../assets/img/pharmacy/medicalCommission.svg';
import { ReactComponent as Recipe } from '../../../../../assets/img/pharmacy/recipe.svg';
import { ReactComponent as Stamp } from '../../../../../assets/img/pharmacy/stamp.svg';

interface Form14804Props {
  onInputChange?: (id: number, value: string) => void;
  initialValues: InitialValuesType;
  onCheckboxStateChange?: (checkboxStates: {
    inputs: { [key: number]: boolean };
    svgs: { [key: number]: boolean };
  }) => void;
  isShow?: boolean;
  isReadOnly?: boolean;
  isShowDeleteButton?: boolean;
  formDroppedItems?: SvgType[];
  handleDragStart?: (index: number, type: string) => void,
  handleDragOver?: () => void,
  handleDrop?: () => void,
  onSvgDelete?: (index: number, id: number) => void,
  isConstructor?: boolean
  formCheckboxStates
  isChatPage?: boolean
}

interface SvgType {
  id: number;
  type: string;
  x: number;
  y: number;
  isWrongAnswer: boolean;
}

interface InitialValuesType {
  inputs?: {
    [key: number]: {
      value: string | undefined;
      right?: boolean;
      [key: string]: any;
    };
  };
  svgs?: Array<SvgType>;
  [key: number]: string | number | boolean | null;
}



const Form14804 = forwardRef(({
  initialValues,
  onInputChange,
  formDroppedItems = [],
  handleDragStart,
  handleDragOver,
  handleDrop,
  onSvgDelete,
  isShow,
  isReadOnly,
  onCheckboxStateChange,
  isConstructor,
  isChatPage
}: Form14804Props, ref) => {
  const [inputCheckboxStates, setInputCheckboxStates] = useState<{ [key: number]: boolean }>({});
  const [svgCheckboxStates, setSvgCheckboxStates] = useState<{ [key: number]: boolean }>({});
  const [values, setValues] = useState(() => ({
    inputs: initialValues?.inputs || {},
  }));

  useEffect(() => {
    setValues((prevValues) => ({
      inputs: {
        ...prevValues.inputs,
        ...initialValues.inputs,
      },
    }));
  }, [initialValues.inputs]);

  useEffect(() => {
    if (initialValues?.inputs) {
      const initialCheckboxStates = Object.fromEntries(
        Object.entries(initialValues.inputs).map(([id, input]) => [
          Number(id),
          input.right || false,
        ])
      );
      setInputCheckboxStates(initialCheckboxStates);
    }
  }, [initialValues?.inputs]);

  useEffect(() => {
    if (initialValues?.svgs) {
      const initialSvgCheckboxStates = Object.fromEntries(
        initialValues.svgs.map((svg) => [svg.id, svg.isWrongAnswer || false])
      );
      setSvgCheckboxStates(initialSvgCheckboxStates);
    }
  }, [initialValues?.svgs]);

  const resetAllFields = () => {
    setValues({ inputs: {} });
    setInputCheckboxStates({});
    setSvgCheckboxStates({});
  };

  useImperativeHandle(ref, () => ({
    resetAllFields,
  }));

  const handleInputChangeLocal = (id: number, value: string) => {
    setValues((prev) => ({
      ...prev,
      inputs: {
        ...prev.inputs,
        [id]: {
          ...prev.inputs[id],
          value,
        },
      },
    }));
    onInputChange?.(id, value);
  };

  const handleInputCheckboxChange = (id: number) => {
    setInputCheckboxStates((prev) => {
      const newState = { ...prev, [id]: !prev[id] };

      setValues((prevValues) => ({
        inputs: {
          ...prevValues.inputs,
          [id]: {
            ...prevValues.inputs[id],
            right: newState[id],
          },
        },
      }));

      onCheckboxStateChange?.({ inputs: newState, svgs: svgCheckboxStates });
      return newState;
    });
  };

  const handleSvgCheckboxChange = (id: number) => {
    setSvgCheckboxStates((prev) => {
      const newState = { ...prev, [id]: !prev[id] };

      onCheckboxStateChange?.({ inputs: inputCheckboxStates, svgs: newState });
      return newState;
    });
  };

  return (
    <div style={{
      width: '1200px',
      margin: '0 auto',
      paddingBottom: 50,
      paddingTop: 30,
      position: 'relative'
    }}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 40 }}>
        <span>ФОРМА РЕЦЕПТУРНОГО БЛАНКА N 148-1/У-04(Л)</span>
      </div>
      {[...initialValues.svgs || [], ...formDroppedItems].map((svg, index) => (
        <div
          key={`svg-${svg.id}-${index}`}
          style={{
            position: 'absolute',
            left: `${svg.x}px`,
            top: `${svg.y}px`,
            cursor: 'move',
          }}
          draggable={isConstructor}
          onDragStart={() => handleDragStart?.(index, 'response')}
        >
          {svg.type === 'Doctor' && <Doctor />}
          {svg.type === 'MedicalCommission' && <MedicalCommission />}
          {svg.type === 'Recipe' && <Recipe />}
          {svg.type === 'Stamp' && <Stamp />}
          {isShow && (
            <input
              type="checkbox"
              checked={svgCheckboxStates[svg.id] || false}
              style={{ position: 'absolute', top: -10, left: -10 }}
              onChange={() => handleSvgCheckboxChange(svg.id)}
            />
          )}
          {isConstructor && (
            <button
              style={{
                position: 'absolute',
                background: '#ff665e',
                color: 'white',
                border: 'none',
                borderRadius: 4,
                width: 20,
                height: 20,
                top: -10,
                right: -10,
                cursor: 'pointer',
              }}
              onClick={() => onSvgDelete?.(index, svg.id)}
            >
              ×
            </button>
          )}
        </div>
      ))}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
        <div style={{ width: 250 }}>
          <span>Министерство здравоохранения Российской Федерации</span>
        </div>
        <div style={{ display: 'flex', gap: 20, width: 300 }}>
          <span>МЕСТО ДЛЯ ШТРИХКОДА *</span>
          <span>
            УТВЕРЖДЕНА приказом Министерства здравоохранения Российской Федерации от 24 ноября 2021 г. N 1094н
          </span>
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>Штамп код</span>
        <CustomInput
          id={1}
          onChange={handleInputChangeLocal}
          inputCount={5}
          value={values.inputs?.[1]?.value ?? ''}
          disabled={isReadOnly}
        />
        {isShow && (
          <input
            style={{ width: 25, height: 25, marginLeft: 20 }}
            type="checkbox"
            id="checkbox-1"
            checked={inputCheckboxStates[1] || false}
            onChange={() => handleInputCheckboxChange(1)}
          />
        )}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginBottom: 20 }}>
        <span>Медицинской организации</span>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <CustomInput
            id={2}
            onChange={handleInputChangeLocal}
            inputCount={15}
            value={values.inputs?.[2]?.value ?? ''}
            disabled={isReadOnly}
          />
          {isShow && (
            <input
              style={{ width: 25, height: 25, marginLeft: 20 }}
              type="checkbox"
              id="checkbox-2"
              checked={inputCheckboxStates[2] || false}
              onChange={() => handleInputCheckboxChange(2)}
            />
          )}
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
        <span>Код формы по ОКУД 3108805</span>
      </div>
      <span>Форма N 148-1/у-04(л)</span>
      <div style={{ display: 'flex', border: '1px solid black' }}>
        <div style={{ borderRight: '1px solid black', padding: 10 }}>
          <div>
            <span>Код категории граждан</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CustomInput
              id={5}
              onChange={handleInputChangeLocal}
              inputCount={3}
              value={values.inputs?.[5]?.value ?? ''}
              disabled={isReadOnly}
            />
            {isShow && (
              <input
                style={{ width: 25, height: 25, marginLeft: 20 }}
                type="checkbox"
                id="checkbox-5"
                checked={inputCheckboxStates[5] || false}
                onChange={() => handleInputCheckboxChange(5)}
              />
            )}
          </div>
        </div>
        <div style={{ borderRight: '1px solid black', padding: 10 }}>
          <div>
            <span>Код нозологической формы (по МКБ)</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CustomInput
              id={6}
              onChange={handleInputChangeLocal}
              inputCount={5}
              value={values.inputs?.[6]?.value ?? ''}
              disabled={isReadOnly}
            />
            {isShow && (
              <input
                style={{ width: 25, height: 25, marginLeft: 20 }}
                type="checkbox"
                id="checkbox-6"
                checked={inputCheckboxStates[6] || false}
                onChange={() => handleInputCheckboxChange(6)}
              />
            )}
          </div>
        </div>
        <div style={{ borderRight: '1px solid black', padding: 10 }}>
          <div>
            <span>Код нозологической формы (по МКБ)</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>1. Федеральный бюджет</span>
            <span>2. Бюджет субъекта Российской Федерации</span>
            <span>3. Муниципальный бюджет</span>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 10 }}>
          <span style={{ marginBottom: 20 }}>% оплаты: (подчеркнуть)</span>
          <span>1. Бесплатно</span>
          <span>2. 50%</span>
          <span>3. иной %</span>
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', margin: '20px auto', gap: 20, justifyContent: 'column' }}>
        <span>РЕЦЕПТ </span>
        <div style={{ display: 'flex', justifyContent: 'row' }}>
          <span>Серия</span>
          <input
            type="text"
            style={{ borderLeft: 'none', borderRight: 'none', borderTop: 'none', width: 40 }}
            value={values.inputs?.[15]?.value ?? ''}
            onChange={(event) => {
              handleInputChangeLocal(15, event.target.value);
            }}
            disabled={isReadOnly}
          />
          <span>N</span>
          <input
            type="text"
            style={{ borderLeft: 'none', borderRight: 'none', borderTop: 'none' }}
            value={values.inputs?.[16]?.value ?? ''}
            onChange={(event) => {
              handleInputChangeLocal(16, event.target.value);
            }}
            disabled={isReadOnly}
          />
          {isShow && (
            <input
              style={{ width: 25, height: 25, marginLeft: 20 }}
              type="checkbox"
              id="checkbox-16"
              checked={inputCheckboxStates[16] || false}
              onChange={() => handleInputCheckboxChange(16)}
            />
          )}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <span>Дата оформления:</span>
          <div style={{ marginRight: 20 }}>
            <CustomInput
              id={7}
              onChange={handleInputChangeLocal}
              inputCount={2}
              value={values.inputs?.[7]?.value ?? ''}
              disabled={isReadOnly}
            />
          </div>
          <div>
            <CustomInput
              id={8}
              onChange={handleInputChangeLocal}
              inputCount={2}
              value={values.inputs?.[8]?.value ?? ''}
              disabled={isReadOnly}
            />
          </div>
          <span>20</span>
          <input
            style={{ borderLeft: 'none', borderRight: 'none', borderTop: 'none', width: 30 }}
            value={values.inputs?.[17]?.value ?? ''}
            onChange={(event) => {
              handleInputChangeLocal(17, event.target.value);
            }}
            disabled={isReadOnly}
          />
          <span>г.</span>
          {isShow && (
            <input
              style={{ width: 25, height: 25, marginLeft: 20 }}
              type="checkbox"
              id="checkbox-17"
              checked={inputCheckboxStates[17] || false}
              onChange={() => handleInputCheckboxChange(17)}
            />
          )}
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: 25, marginBottom: 20 }}>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
          <div style={{ display: 'flex', alignItems: 'center', width: 600, flexDirection: 'row' }}>
            <span>
              Фамилия, инициалы имени и отчества (последнее - при наличии) пациента
              <input
                style={{ width: 500, borderLeft: 'none', borderRight: 'none', borderTop: 'none' }}
                type="text"
                value={values.inputs?.[18]?.value ?? ''}
                onChange={(event) => {
                  handleInputChangeLocal(18, event.target.value);
                }}
                disabled={isReadOnly}
              />
              {isShow && (
                <input
                  style={{ width: 25, height: 25, marginLeft: 20 }}
                  type="checkbox"
                  id="checkbox-18"
                  checked={inputCheckboxStates[18] || false}
                  onChange={() => handleInputCheckboxChange(18)}
                />
              )}
            </span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', width: 600, flexDirection: 'row' }}>
            <span> Дата рождения</span>
            <div style={{ display: 'flex', flexDirection: 'row', marginRight: 20 }}>
              <CustomInput
                id={9}
                onChange={handleInputChangeLocal}
                inputCount={2}
                value={values.inputs?.[9]?.value ?? ''}
                disabled={isReadOnly}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginRight: 20 }}>
              <CustomInput
                id={10}
                onChange={handleInputChangeLocal}
                inputCount={2}
                value={values.inputs?.[10]?.value ?? ''}
                disabled={isReadOnly}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginRight: 20 }}>
              <CustomInput
                id={11}
                onChange={handleInputChangeLocal}
                inputCount={2}
                value={values.inputs?.[11]?.value ?? ''}
                disabled={isReadOnly}
              />
            </div>
            {isShow && (
              <input
                style={{ width: 25, height: 25, marginLeft: 20 }}
                type="checkbox"
                id="checkbox-18"
                checked={inputCheckboxStates[11] || false}
                onChange={() => handleInputCheckboxChange(11)}
              />
            )}
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', padding: 20 }}>
        <span>
          Номер медицинской карты пациента, получающего медицинскую помощь в амбулаторных условиях
        </span>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <input
            style={{ width: '100%', borderLeft: 'none', borderRight: 'none', borderTop: 'none' }}
            value={values.inputs?.[19]?.value ?? ''}
            onChange={(event) => {
              handleInputChangeLocal(19, event.target.value);
            }}
            type="text"
            disabled={isReadOnly}
          />
          {isShow && (
            <input
              style={{ width: 25, height: 25, marginLeft: 20 }}
              type="checkbox"
              id="checkbox-19"
              checked={inputCheckboxStates[19] || false}
              onChange={() => handleInputCheckboxChange(19)}
            />
          )}
        </div>

      </div>
      <div style={{ display: 'flex', flexDirection: 'row', padding: 20 }}>
        <span>
          Фамилия, инициалы имени и отчества лечащего врача (фельдшера, акушерки)
          <input
            style={{ width: '100%', borderLeft: 'none', borderRight: 'none', borderTop: 'none' }}
            value={values.inputs?.[20]?.value ?? ''}
            onChange={(event) => {
              handleInputChangeLocal(20, event.target.value);
            }}
            type="text"
            disabled={isReadOnly}
          />
        </span>
        {isShow && (
          <input
            style={{ width: 25, height: 25, marginLeft: 20 }}
            type="checkbox"
            id="checkbox-20"
            checked={inputCheckboxStates[20] || false}
            onChange={() => handleInputCheckboxChange(20)}
          />
        )}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 45, padding: 20, width: '100%', marginBottom: 40 }}>
        <span>Руб.</span>
        <span>Коп.</span>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, width: '100%' }}>
          <span>
            RP:
            <input
              style={{
                borderLeft: 'none',
                borderTop: 'none',
                borderRight: 'none',
                borderBottom: '1px dotted black',
                width: '90%',
              }}
              value={values.inputs?.[21]?.value ?? ''}
              onChange={(event) => {
                handleInputChangeLocal(21, event.target.value);
              }}
              disabled={isReadOnly}
            />
            {isShow && (
              <input
                style={{ width: 25, height: 25, marginLeft: 20 }}
                type="checkbox"
                id="checkbox-20"
                checked={inputCheckboxStates[21] || false}
                onChange={() => handleInputCheckboxChange(21)}
              />
            )}
          </span>
          <span>
            D.t.d.
            <input
              style={{
                borderLeft: 'none',
                borderTop: 'none',
                borderRight: 'none',
                borderBottom: '1px dotted black',
                width: '90%',
              }}
              value={values.inputs?.[22]?.value ?? ''}
              onChange={(event) => {
                handleInputChangeLocal(22, event.target.value);
              }}
              disabled={isReadOnly}
            />
            {isShow && (
              <input
                style={{ width: 25, height: 25, marginLeft: 20 }}
                type="checkbox"
                id="checkbox-20"
                checked={inputCheckboxStates[22] || false}
                onChange={() => handleInputCheckboxChange(22)}
              />
            )}
          </span>
          <span>
            Signa:
            <input
              style={{
                borderLeft: 'none',
                borderTop: 'none',
                borderRight: 'none',
                borderBottom: '1px dotted black',
                width: '90%',
              }}
              value={values.inputs?.[23]?.value ?? ''}
              onChange={(event) => {
                handleInputChangeLocal(23, event.target.value);
              }}
              disabled={isReadOnly}
            />
            {isShow && (
              <input
                style={{ width: 25, height: 25, marginLeft: 20 }}
                type="checkbox"
                id="checkbox-20"
                checked={inputCheckboxStates[23] || false}
                onChange={() => handleInputCheckboxChange(23)}
              />
            )}
          </span>
        </div>
      </div>
      <div style={{ borderBottom: '1px solid black', marginBottom: 35 }}></div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 40 }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
          <span>Подпись и печать лечащего врача</span>
          <span>(подпись фельдшера, акушерки)</span>
        </div>
        <span style={{ width: '50%' }}>М.П.</span>
      </div>
      <div style={{ textAlign: 'center', marginBottom: 40 }}>
        <span>Рецепт действителен в течение 15 дней, 30 дней, 90 дней (нужное подчеркнуть)</span>
      </div>
      <div style={{ textAlign: 'center', marginBottom: 10 }}>
        <span>------------------- (Заполняется специалистом аптечной организации) -------------------</span>
      </div>
      <div style={{ display: 'flex', width: '80%', margin: '0 auto', border: '1px solid black' }}>
        <div style={{ borderRight: '1px solid black', width: '100%' }}>
          <div style={{ borderBottom: '1px solid black', padding: 10 }}>
            <span>Отпущено по рецепту:</span>
            <input
              style={{ borderLeft: 'none', borderTop: 'none', borderRight: 'none' }}
              value={values.inputs?.[24]?.value ?? ''}
              onChange={(event) => {
                handleInputChangeLocal(24, event.target.value);
              }}
              type="text"
              disabled={isReadOnly}
            />
            {isShow && (
              <input
                style={{ width: 25, height: 25, marginLeft: 20 }}
                type="checkbox"
                id="checkbox-20"
                checked={inputCheckboxStates[24] || false}
                onChange={() => handleInputCheckboxChange(24)}
              />
            )}
          </div>
          <div style={{ borderBottom: '1px solid black', padding: 10, display: 'flex', flexDirection: 'row' }}>
            <span>Дата отпуска:</span>
            <span>"</span>
            <input
              style={{ borderLeft: 'none', borderTop: 'none', borderRight: 'none' }}
              value={values.inputs?.[25]?.value ?? ''}
              onChange={(event) => {
                handleInputChangeLocal(25, event.target.value);
              }}
              type="text"
              disabled={isReadOnly}
            />
            <span>"</span>
            <input
              style={{ borderLeft: 'none', borderTop: 'none', borderRight: 'none', width: 100 }}
              value={values.inputs?.[26]?.value ?? ''}
              onChange={(event) => {
                handleInputChangeLocal(26, event.target.value);
              }}
              type="text"
              disabled={isReadOnly}
            />
            <span>20</span>
            <span>г.</span>
            <input
              style={{ borderLeft: 'none', borderTop: 'none', borderRight: 'none', width: 30 }}
              value={values.inputs?.[27]?.value ?? ''}
              onChange={(event) => {
                handleInputChangeLocal(27, event.target.value);
              }}
              type="text"
              disabled={isReadOnly}
            />
            {isShow && (
              <input
                style={{ width: 25, height: 25, marginLeft: 20 }}
                type="checkbox"
                id="checkbox-20"
                checked={inputCheckboxStates[27] || false}
                onChange={() => handleInputCheckboxChange(27)}
              />
            )}
          </div>
        </div>
        <div style={{ width: '100%', padding: 10 }}>
          <span>Торговое наименование и дозировка:</span>
          <div style={{ borderBottom: '1px solid black', padding: 10, display: 'flex', flexDirection: 'row' }}>
            <input
              style={{ borderLeft: 'none', borderTop: 'none', borderRight: 'none' }}
              value={values.inputs?.[29]?.value ?? ''}
              onChange={(event) => {
                handleInputChangeLocal(29, event.target.value);
              }}
              type="text"
              disabled={isReadOnly}
            />
            {isShow && (
              <input
                style={{ width: 25, height: 25, marginLeft: 20 }}
                type="checkbox"
                id="checkbox-20"
                checked={inputCheckboxStates[29] || false}
                onChange={() => handleInputCheckboxChange(29)}
              />
            )}
          </div>
          <div style={{ borderBottom: '1px solid black', padding: '10px 0' }}>
            <span>Количество:</span>
            <input
              style={{ borderLeft: 'none', borderTop: 'none', borderRight: 'none' }}
              value={values.inputs?.[30]?.value ?? ''}
              onChange={(event) => {
                handleInputChangeLocal(30, event.target.value);
              }}
              type="text"
              disabled={isReadOnly}
            />
            {isShow && (
              <input
                style={{ width: 25, height: 25, marginLeft: 20 }}
                type="checkbox"
                id="checkbox-20"
                checked={inputCheckboxStates[30] || false}
                onChange={() => handleInputCheckboxChange(30)}
              />
            )}
          </div>
        </div>
      </div>
      <span style={{ display: 'flex', justifyContent: 'center', margin: '25px auto', width: '100%' }}>
        --------------------------------------- (линия отрыва) ---------------------------------------
      </span>
      <div style={{ display: 'flex', width: '80%', margin: '0 auto', border: '1px solid black' }}>
        <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid black', padding: 10, width: '100%' }}>
          <div>
            <span>Корешок рецептурного бланка:</span>
            <input
              style={{ borderLeft: 'none', borderTop: 'none', borderRight: 'none' }}
              value={values.inputs?.[33]?.value ?? ''}
              onChange={(event) => {
                handleInputChangeLocal(33, event.target.value);
              }}
              type="text"
              disabled={isReadOnly}
            />
            {isShow && (
              <input
                style={{ width: 25, height: 25, marginLeft: 20 }}
                type="checkbox"
                id="checkbox-20"
                checked={inputCheckboxStates[33] || false}
                onChange={() => handleInputCheckboxChange(33)}
              />
            )}
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', padding: 10, width: '100%' }}>
          <div>
            <span>Способ применения:</span>
            <input
              style={{ borderLeft: 'none', borderTop: 'none', borderRight: 'none' }}
              value={values.inputs?.[36]?.value ?? ''}
              onChange={(event) => {
                handleInputChangeLocal(36, event.target.value);
              }}
              type="text"
              disabled={isReadOnly}
            />
            {isShow && (
              <input
                style={{ width: 25, height: 25, marginLeft: 20 }}
                type="checkbox"
                id="checkbox-20"
                checked={inputCheckboxStates[36] || false}
                onChange={() => handleInputCheckboxChange(36)}
              />
            )}
          </div>
          <div>
            <span>Продолжительность:</span>
            <input
              style={{ borderLeft: 'none', borderTop: 'none', borderRight: 'none' }}
              value={values.inputs?.[37]?.value ?? ''}
              onChange={(event) => {
                handleInputChangeLocal(37, event.target.value);
              }}
              type="text"
              disabled={isReadOnly}
            />
            <span>дней</span>
            {isShow && (
              <input
                style={{ width: 25, height: 25, marginLeft: 20 }}
                type="checkbox"
                id="checkbox-20"
                checked={inputCheckboxStates[37] || false}
                onChange={() => handleInputCheckboxChange(37)}
              />
            )}
          </div>
          <div>
            <span>Количество приемов в день:</span>
            <input
              style={{ borderLeft: 'none', borderTop: 'none', borderRight: 'none' }}
              value={values.inputs?.[38]?.value ?? ''}
              onChange={(event) => {
                handleInputChangeLocal(38, event.target.value);
              }}
              type="text"
              disabled={isReadOnly}
            />
            <span>раз</span>
            {isShow && (
              <input
                style={{ width: 25, height: 25, marginLeft: 20 }}
                type="checkbox"
                id="checkbox-20"
                checked={inputCheckboxStates[38] || false}
                onChange={() => handleInputCheckboxChange(38)}
              />
            )}
          </div>
          <div>
            <span>На 1 прием:</span>
            <input
              style={{ borderLeft: 'none', borderTop: 'none', borderRight: 'none' }}
              value={values.inputs?.[39]?.value ?? ''}
              onChange={(event) => {
                handleInputChangeLocal(39, event.target.value);
              }}
              type="text"
              disabled={isReadOnly}
            />
            <span>ед</span>
            {isShow && (
              <input
                style={{ width: 25, height: 25, marginLeft: 20 }}
                type="checkbox"
                id="checkbox-20"
                checked={inputCheckboxStates[39] || false}
                onChange={() => handleInputCheckboxChange(39)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );

});

export default Form14804;