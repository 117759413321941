import React, { useEffect, useState } from 'react';
import ReactCodeInput from 'react-code-input';

interface CustomInputInterface {
  id: number;
  onChange: (id: number, value: string) => void;
  inputCount: number;
  height?: string;
  value?: string; 
  disabled?: boolean;
}

export default function CustomInput({ id, onChange, inputCount, height, value = '', disabled }: CustomInputInterface) {
  const [inputValue, setInputValue] = useState(
    String(value || '').padEnd(inputCount, ' ')
  );
  

  useEffect(() => {
    setInputValue(String(value || '').padEnd(inputCount, ' '));
  }, [value, inputCount]);
  
  const props = {
    inputStyle: {
      fontFamily: 'monospace',
      margin: '4px',
      MozAppearance: 'textfield',
      width: '40px',
      borderRadius: '3px',
      fontSize: '14px',
      height: height ? `${height}px` : '26px',
      paddingLeft: '7px',
      backgroundColor: 'white',
      color: 'black',
      border: '1px solid black',
    },
    inputStyleInvalid: {
      fontFamily: 'monospace',
      margin: '4px',
      MozAppearance: 'textfield',
      width: '40px',
      borderRadius: '3px',
      fontSize: '14px',
      height: '26px',
      paddingLeft: '7px',
      backgroundColor: 'black',
      color: 'red',
      border: '1px solid red',
    },
  };

  return (
    <div>
      <ReactCodeInput
        type="text"
        fields={inputCount}
        value={inputValue}
        onChange={(value) => {
          setInputValue(value.padEnd(inputCount, ' '));
          onChange(id, value.trim()); 
        }}
        {...props}
        disabled={disabled} 
      />
    </div>
  );
}
