import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ChatMessage } from '../../types';
import {
  Medicine_ResponseData,
  StudentSpeciality_ResponseData,
  AuthenticateRequest,
  BaseResponse,
  Rating_BestUser,
  Rating_Activity,
  Rating_Line,
  Constructors_Task,
  Constructors_Task_Send,
  Constructors_Communication,
  ChatApi,
  JournalHistory_ResponseData,
  JwtToken,
  RegistrationRequest,
  SendMessage_ResponseData,
  Task_ResponseData,
  TaskProceed_ResponseData,
  TypeOfDifficultLevel_ResponseData,
  CheckJournalHistory_ResponseData,
  GroupOfResearch_ResponseData,
  ResearchItem_RequestData,
  ResearchResults_ResponseData,
  TaskResult_ResponseData,
  DiagnosisTypes_ResponseData,
  Diagnosis_ReqData,
  DiagnosisFrom_ResponseData,
  TypeOfResearchSearchResult_Response,
  ReleaseForm,
  GroupOfMedicine,
  ReceptionMethod,
  TimeOfReceipt,
  ConnectionWithFood,
  ConnectionWithSleep,
  AdditionalRestriction,
  ReceptionPeriod_NetData,
  PrescribedMedication__NetData,
  PrescribedMedication__ResponseData,
  PrescribedMedicationResult__ResponseData,
  DietType_ResponseData,
  FoodMedication_GetResponse_NetData,
  DosageUnit_ResponseData,
  NotMedicalFlag_ResponseData,
  NotMedicalType_ResponseData,
  NotMedicalMedication_NetData,
  MedicalResult_ResponseData,
  DietMedication_CreateUpdateRequest_NetData,
  ScheduleMedication_CreateUpdate_NetData,
  DietMedication_GetResponse_NetData,
  TargetUseType_NetData,
  ScheduleMedication_GetResponse_NetData,
  FoodMedication_CreateUpdate_NetData,
  AlternativeMedicationType_GetResponse,
  ActiveMedication_CreateUpdate_NetData,
  ActiveMedication_GetResponse_NetData,
  PassiveMedication_CreateUpdate_NetData,
  PassiveMedication_GetResponse_NetData,
  PassiveActiveTreatment_GetResponse_NetData,
  BodyPartImage_GetResponse_NetData,
  InspectionMethod_GetResponse_NetData,
  InspectionSide_GetResponse_NetData,
  PartOfBody_GetResponse_NetData,
  PointOfView_GetResponse_NetData,
  University_Level,
  Patient_Model,
  Type_Of_Patient_Status,
  Constructors_Additional,
  BodyPartImage_Props,
  PointsOfView_Props,
  TaskRating_ResponseData,
  Constructors_PatientParams,
  Type_DescriptionList,
  Type_ResearchUnit,
  Type_ParametersForDefaultResearch,
  Type_ResearchDefaultByTypeId,
  IIllness,
  Get_Test,
  bodyGetTest,
  set_Test_Answers,
  type_Anamnesis,
  Constructors_ClinicalRelevance,
  get_Connection_With_Illness,
  get_Default_Mean_List,
  create_Mean,
  update_Meaning,
  delete_Meaning,
  get_Objective_Status,
  get_Current_Objective_Status,
  get_Excrement_Photo,
  get_Urine_Status,
  get_Constructor_Additional_Results,
  get_Front_Body_Part_Image,
  get_Inspection_Side,
  get_localisations_place,
  get_Medical_Dictionary_All,
  get_Medical_Treatment,
  get_Reference,
  get_Standart_Alternative_Medication,
  update_Current_Objective_status,
  get_Medical_Dictionary,
  Point_Of_View,
  Update_Research_Instrument,
  Create_Or_Update_Schedule,
  Add_Diet,
  CreateOrUpdateNotTreatment,
  Treatment_Item_Type,
  createResearchInstrument,
  CompetenceType,
  SpecialtiesInterface,
  modesReferenceInterface,
  nursingCareInterface,
  noneMedicalTreatment,
  surgicalTreatmentConstructorInterface,
  matrixCompetenceInterface,
  depersonalizedRatingInterface,
  nursingCareListInterface,
  treatmentSchemeInterface,
  groupOfResearchInterface, reviewCreate,
  Constructors_ReferenceDescription,
  Constructors_ConnectionWithIllness,
  Constructors_UrinePressure,
  Constructors_UrineColor,
  Constructors_UrineImpurity,
  Constructors_UrineTransparency,
  Constructors_UrineFoam,
  Constructors_UrineComfort,
  Constructors_UrineAdditionalFenomen,
  Constructors_ExcrementColor,
  Constructors_ExcrementComfort,
  Constructors_ExcrementPhoto,
  Constructors_ExcrementSmell,
  Constructors_ClinicalDiagnosis,
  models,
  allPatient,
  createdSpecialist,
  questionsInterface,
  currentAnswers,
  taskQuestion,
  pilotingTaskInterface,
  reviewLogAnswers,
  GetHint,
  PatientModelVoice,
  Constructors_ClinicalDiagnosisMedicalDictionary,
  Constructors_ClinicalDiagnosisInformationLevel,
  ClinicalDiagnosisTaskProceed,
  sendTestResultOnEmail, themeInterface,
  Rheumatology_State,
  Constructor_Reference,
  LegendOption,
  RecipeTaskProceed,
  RecipeTaskDetail,
  RecipeTask
} from './chatApi';
import { store } from '../../store';
import { AuthAction } from '../../store/auth';
import { buildConfig, BuildType } from '../../BuildConfig';
import { delay } from '../../common/Utils';




export class ChatApiImpl implements ChatApi {
  constructor(public readonly http: AxiosInstance) { }
  [x: string]: any;
  getThemesGroup() {
    return this.http.get(`api/group_themes`).then((it: any) => it.data);
  }
  //Консультация специалистов
  getAllSpecialists() {
    return this.http.get(`api/anamnesis_specialist_advice/all`).then((it: any) => it.data);
  }
  createSpecialist(taskId: number, id: number) {
    return this.http.post(`api/prescribed_specialist_advice/create`, {
      userTaskProceedId: taskId,
      anamnesisId: id
    }).then((it: any) => it.data);
  }
  getAnswerSpecialists(task: number) {
    return this.http.get(`api/prescribed_specialist_advice/get_result/${task}`).then((it: any) => it.data);
  }
  getCheckAnswer(id: number) {
    return this.http.get(`api/prescribed_specialist_advice/get_task/${id}`).then((it: any) => it.data);
  }
  // Чемпионаты
  getAllChampionships() {
    return this.http.get(`api/championship/get_by_user`).then((it: any) => it.data);
  }
  getRegisterChamp(id: number) {
    return this.http.put(`api/championship/put_user_championship_registration/${id}`).then((it: any) => it.data);
  }
  saveProcced(task: number, champ: number, healing: boolean) {
    return this.http.post(`/api/task-proceed/save`, { task_id: task, championship_id: champ, healing: healing }).then((it: any) => it.data);
  }
  registeredUsers(id: number) {
    return this.http.get(`api/championship/user_count/${id}`).then((it: any) => it.data);
  }
  userListPoint(id: number) {
    return this.http.get(`api/championship/get_users_list_with_point_and_stage/${id}`).then((it: any) => it.data);
  }
  //

  //ADMIN
  getAllUsersAdmin(logins?: Array<string>, trainerIds?: Array<number>, orgIds?: Array<number>): Promise<any> {
    return this.http
      .post(`/api/admin_user/users`, {
        trainerIds: trainerIds,
        orgIds: orgIds,
        logins: logins,
      })
      .then((it: any) => it.data);
  }
  getUserAdmin(login: string) {
    return this.http.get(`/api/admin_user/${login}`).then((it: any) => it.data);
  }
  createUserAdmin(login: string, firstName: string, lastName: string, patronymic: string, email: string, imageUrl: string, langKey: string, password: string) {
    return this.http
      .post(`api/admin_user/create`, {
        login: login,
        firstName: firstName,
        lastName: lastName,
        patronymic: patronymic,
        email: email,
        imageUrl: imageUrl,
        langKey: langKey,
        password: password,
      })
      .then((it: any) => it.data);
  }
  editUserAdmin(login: string, firstName: string, lastName: string, patronymic: string, email: string, imageUrl: string, langKey: string, activate: boolean) {
    return this.http
      .put(`api/admin_user/update`, {
        login: login,
        firstName: firstName,
        lastName: lastName,
        patronymic: patronymic,
        email: email,
        imageUrl: imageUrl,
        langKey: langKey,
        activate: activate,
      })
      .then((it: any) => it.data);
  }
  savePassword(currentPassword: string, newPassword: string) {
    return this.http
      .post(`api/account/change-password`, {
        currentPassword: currentPassword,
        newPassword: newPassword,
      })
      .then((it: any) => it.data);
  }

  resetPasswordFinish(key: string, newPassword: string) {
    return this.http
      .post(`api/account/reset-password/finish`, {
        key: key,
        newPassword: newPassword,
      })
      .then((it: any) => it.data);
  }

  dataTable(sData?: any, poData?: any) {
    return this.http
      .post(`/api/user_activity_logs/all`, {
        from: sData,
        to: poData,
      })
      .then((it: any) => it.data);
  }
  getInfoRole(userId: any) {
    return this.http.get(`/api/userauthorities/info/${userId}`).then((it: any) => it.data);
  }
  createRole(userId: any) {
    return this.http.get(`/api/userauthorities/info/${userId}`).then((it: any) => it.data);
  }
  addRole(userId: number, role: any) {
    return this.http.post(`/api/userauthorities/create/${userId}`, role).then((it: any) => it.data);
  }
  removeRole(userId: number, role: any) {
    return this.http.delete(`/api/userauthorities/delete/authority/${userId}/${role}`).then((it: any) => it.data);
  }
  addOrg() {
    return this.http.post(`/api/userauthorities/requests/org`, {}).then((it: any) => it.data);
  }
  addTrn() {
    return this.http.post(`/api/userauthorities/requests/trn`, {}).then((it: any) => it.data);
  }
  allOrg(data: string | number) {
    return this.http.get(`/api/userorganization/get/${data}`, {}).then((it: any) => it.data);
  }
  allTrn(data: string | number) {
    return this.http.get(`/api/usertrainer/get/${data}`, {}).then((it: any) => it.data);
  }
  deleteOrg(userId: any, orgId: any) {
    return this.http.delete(`/api/userauthorities/delete/org/${userId}/${orgId}`, {}).then((it: any) => it.data);
  }
  deleteTrn(userId: any, orgId: any) {
    return this.http.delete(`/api/userauthorities/delete/trn/${userId}/${orgId}`, {}).then((it: any) => it.data);
  }
  requestOrg(userId: number, orgId: number, action: number) {
    return this.http
      .post(`/api/userauthorities/requests/create/org`, {
        userId: userId,
        trainerOrganizationId: orgId,
        action: action,
      })
      .then((it: any) => it.data);
  }
  requestTrn(userId: number, orgId: number, action: number) {
    return this.http
      .post(`/api/userauthorities/requests/create/trn`, {
        userId: userId,
        trainerOrganizationId: orgId,
        action: action,
      })
      .then((it: any) => it.data);
  }
  executeOrg(id: number, answer: string, actionAnswer: boolean) {
    return this.http
      .post(`/api/userauthorities/requests/execute/org`, {
        id: id,
        answer: answer,
        actionAnswer: actionAnswer,
      })
      .then((it: any) => it.data);
  }

  executeTrn(id: number, answer: string, actionAnswer: boolean) {
    return this.http
      .post(`/api/userauthorities/requests/execute/trn`, {
        id: id,
        answer: answer,
        actionAnswer: actionAnswer,
      })
      .then((it: any) => it.data);
  }


  listRoles() {
    return this.http.get(`/api/authorities/get/all`).then((it: any) => it.data);
  }
  descriptionRole(role: string) {
    return this.http.get(`/api/authorities/description/get?authority=${role}`).then((it: any) => it.data);
  }
  addDescription(role: string, descrip: string) {
    return this.http.post(`/api/authorities/description/create`, { authority: role, description: descrip }).then((it: any) => it.data);
  }
  updateDescription(id: number, role: string, descrip: string) {
    return this.http.put(`/api/authorities/description/update`, { id: id, authority: role, description: descrip }).then((it: any) => it.data);
  }
  deleteDescription(id: number) {
    return this.http.delete(`/api/authorities/description/delete/${id}`).then((it: any) => it.data);
  }

  adminRating() {
    return this.http.get(`/api/settings_organization/get/all`).then((it: any) => it.data);
  }

  updataAdminRating(id: number, description: string, active: boolean) {
    return this.http.put(`/api/settings_organization/update`, { id: id, description: description, active: active }).then((it: any) => it.data);
  }

  allStudents() {
    return this.http.post(`api/students_teacher/all`, {}).then((it: any) => it.data);
  }

  allTeachers() {
    return this.http.post(`api/teacher/all`, {}).then((it: any) => it.data);
  }
  ///////////////////////////
  loadQuestionsStress(phrasesId: number): Promise<any> {
    return this.http.get(`api/trigger_question/get_number_of_questions/${phrasesId}`).then((it: any) => it.data);
  }
  loadQuestionsStep(phrasesId: number, questionNumber: number): Promise<any> {
    return this.http.get(`api/trigger_question/get_question/${phrasesId}/${questionNumber}`).then((it: any) => it.data);
  }
  toggleStress(login: string): Promise<any> {
    return this.http.get(`/api/admin_user/${login}`).then((it: any) => it.data);
  }
  stressHistory(dates: any): Promise<any> {
    return this.http.post(`api/trigger_history/create`, dates).then((it: any) => it.data);
  }
  loadAnalysisStress(): Promise<any> {
    return this.http.get(`/api/trigger_history/get_analysis`).then((it: any) => it.data);
  }
  cognitiveHistory(): Promise<any> {
    return this.http.get(`/api/cognitive/history`).then((it: any) => it.data);
  }

  cognitiveUnfinishedTest(): Promise<any> {
    return this.http.get(`/api/cognitive/unfinished_test`).then((it: any) => it.data);
  }

  cognitiveGetQuestion(testId: number, questionId: number): Promise<any> {
    return this.http.get(`/api/cognitive/getQuestion/${testId}/${questionId}`).then((it: any) => it.data);
  }

  cognitiveGenerate(): Promise<any> {
    return this.http.get(`/api/cognitive/generate`).then((it: any) => it.data);
  }

  cognitiveSaveAnswer(testId: number, questionId: number, answerId: number, timeAnswer: number): Promise<any> {
    return this.http.post(`/api/cognitive/save_answer/${testId}/${questionId}/${answerId}/${timeAnswer}`).then((it: any) => it.data);
  }

  ratingImportanceGroups(uid: string, startDate: string, endDate: string): Promise<any> {
    return this.http.get(`/api/rating/importance/groups/${uid}?startTime=${startDate}&endTime=${endDate}`).then((it: any) => it.data);
  }

  ratingImportanceSubject(uid: string, startDate: string, endDate: string): Promise<any> {
    return this.http.get(`/api/rating/importance/subject/${uid}?startTime=${startDate}&endTime=${endDate}`).then((it: any) => it.data);
  }

  testResultImportanceGroups(): Promise<any> {
    return this.http.get(`/api/testResult/importance/groups`).then((it: any) => it.data);
  }

  testResultImportanceSubject(): Promise<any> {
    return this.http.get(`/api/testResult/importance/subject`).then((it: any) => it.data);
  }

  testResultImportanceTotal(): Promise<any> {
    return this.http.get(`/api/testResult/importance/total`).then((it: any) => it.data);
  }

  getUserTrainers(): Promise<any> {
    return this.http.get(`/api/user/trainers`).then((it: any) => it.data);
  }
  /////Profile
  getUsersMarks(id: number): Promise<any> {
    return this.http.get(`/api/championship/get_user_awards/${id}`).then((it: any) => it.data);
  }

  getAllSpeciality(): Promise<any> {
    return this.http.get(`/api/student_speciality`).then((it: any) => it.data);
  }

  getAllSpecialityMy(userId: number): Promise<any> {
    return this.http.get(`/api/student_speciality/get_by_user/${userId}`).then((it: any) => it.data);
  }

  createSpeciality(studentSpecialityId: number, userId: number): Promise<any> {
    return this.http.post(`/api/user_student_speciality/create`, {
      studentSpecialityId: studentSpecialityId,
      userId: userId
    }).then((it: any) => it.data);
  }

  deleteSpeciality(id: number): Promise<any> {
    return this.http.delete(`/api/user_student_speciality/delete/${id}`).then((it: any) => it.data);
  }

  getAllTeacher(): Promise<any> {
    return this.http.get(`api/teacher/get`).then((it: any) => it.data);
  }

  getTeacherMy(id: number): Promise<any> {
    return this.http.get(`/api/students_teacher/get_by_user/${id}`).then((it: any) => it.data);
  }

  createTeacher(teacherId: number, userId: number): Promise<any> {
    return this.http.post(`/api/students_teacher/create`, {
      teacherId: teacherId,
      userId: userId
    }).then((it: any) => it.data);
  }

  deleteTeacher(id: number): Promise<any> {
    return this.http.delete(`/api/students_teacher/delete/${id}`).then((it: any) => it.data);
  }

  getAllOrgProfile(): Promise<any> {
    return this.http.get(`/api/organization/get`).then((it: any) => it.data);
  }

  getOrgMy(id: number): Promise<any> {
    return this.http.get(`/api/userorganization/get_by_user/${id}`).then((it: any) => it.data);
  }


  getAllTrnProfile(): Promise<any> {
    return this.http.get(`/api/trainer/get`).then((it: any) => it.data);
  }

  getTrnMy(id: number): Promise<any> {
    return this.http.get(`/api/usertrainer/get/by_user/${id}`).then((it: any) => it.data);
  }


  getRating(stop: any, start: any, id: any, org: any, trn: any, option: any) {
    if (option === 1) {
      return this.http.post(`/api/history_rauting/get_rating_profile`,
        {
          date_end: stop,
          date_start: start,
          user_id: id,
          trainer_id: trn,
        }).then((it: any) => it.data);
    }
    else {
      return this.http.post(`/api/history_rauting/get_rating_profile`,
        {
          date_end: stop,
          date_start: start,
          user_id: id,
          organization_id: org,
        }).then((it: any) => it.data);
    }
  }

  // api/standartalternativemedication/result/referral_specialist
  getStandartAlternativeMedication(): Promise<any> {
    return this.http.get(`/api/alterativemedication/gettaskid/referralspecialist`).then((it: any) => it.data);
  }

  // api/alterativemedication/gettaskid/referralspecialist
  getAlterativeMedication(): Promise<any> {
    return this.http.get(`/api/alterativemedication/gettaskid/referralspecialist`).then((it: any) => it.data);
  }
  getDiagnosisOptionsVariantes(level: string | undefined, text: string | null, code: string | null, page: number, size: number | undefined): Promise<any> {
    return this.http.get(`/api/diagnosis_options/variantes?text=${text}&code=${code}&level=${level}&page=${page}&size=${size}`).then((it: any) => it.data);
  }

  getPatientModelByIdTask(id: string): Promise<any> {
    return this.http.get(`/api/task/id/${id}`).then((it: any) => it.data);
  }

  getPatientModelsById(): Promise<any> {
    return this.http.get(`/api/patientmodel/1`).then((it: any) => it.data);
  }

  markPointClicked(pointId: number): Promise<BaseResponse<any>> {
    return this.http.post(`/api/pointsclick/create`, { id: pointId }).then(it => it.data);
  }

  getInspection(id: number | undefined): Promise<BaseResponse<Array<PointOfView_GetResponse_NetData>>> {
    return this.http.get(`api/constructor/inspection/get/${id}`).then(res => res.data);
  }

  getInspectionSides(): Promise<BaseResponse<InspectionSide_GetResponse_NetData[]>> {
    return this.http.get(`/api/inspectionside`).then(it => it.data);
  }
  getPartsOfBody(): Promise<BaseResponse<PartOfBody_GetResponse_NetData[]>> {
    return this.http.get(`/api/partofbody`).then(it => it.data);
  }
  getPartsOfBodyByTask(inspectionMethod: string): Promise<BaseResponse<PartOfBody_GetResponse_NetData[]>> {
    return this.http.get(`/api/partofbody/by_task/${inspectionMethod}`).then(it => it.data);
  }
  getInspectionMethods(): Promise<BaseResponse<InspectionMethod_GetResponse_NetData[]>> {
    return this.http.get(`/api/inspectionmethods`).then(it => it.data);
  }
  getInspectionMethodsByTask(): Promise<BaseResponse<InspectionMethod_GetResponse_NetData[]>> {
    return this.http.get(`/api/inspectionmethods/by_task`).then(it => it.data);
  }
  getPointsOfView(inspectionsideid: string, partofbodyid: string, inspectionmethodid: string): Promise<BaseResponse<PointOfView_GetResponse_NetData[]>> {
    return this.http.get(`/api/pointsofview/${inspectionsideid}/${partofbodyid}/${inspectionmethodid}`).then(it => it.data);
  }

  getAllRheumatologyState(): Promise<BaseResponse<any>> {
    return this.http.get(`/api/rheumatology_state/get_all`).then(it => it.data);
  }

  getBodyPartImage(inspectionsideid: string, partofbodyid: string, inspectionmethodid: string, inspectionTask: string): Promise<BaseResponse<BodyPartImage_GetResponse_NetData[]>> {
    return this.http.get(`/api/bodypartimage/${inspectionsideid}/${partofbodyid}/${inspectionmethodid}/${inspectionTask}`).then(it => it.data);
  }

  getUserInfo(): Promise<BaseResponse<any>> {
    return this.http.get(`/api/user/current`).then(it => it.data);
  }

  async getUserStatus(id: number): Promise<any> {
    const it: any = this.http.get(`/api/authorities/get/${(await this.getUserInfo()).result.id}`);
    return it.data;
  }

  getUserRating(startTime: string, endTime: string): Promise<BaseResponse<number>> {
    return this.http
      .get(`/api/rating/getUserRating?startTime=${startTime}&endTime=${endTime}`)
      .then(it => it.data)
      .catch(err => console.log(err));
  }

  getCountPatient(startTime: string, endTime: string, userId: string): Promise<BaseResponse<number>> {
    return this.http
      .get(`/api/rating/getCountPatient?startTime=${startTime}&endTime=${endTime}&userId=${userId}`)
      .then(it => it.data)
      .catch(err => console.log(err));
  }

  countCompleteTask(startTime: string, endTime: string): Promise<BaseResponse<number>> {
    return this.http.get(`/api/rating/countCompleteTask/all?startTime=${startTime}&endTime=${endTime}`).then(it => it.data);
  }

  countCompleteFailedTask(startTime: string, endTime: string): Promise<BaseResponse<number>> {
    return this.http.get(`/api/rating/countCompleteFailedTask/all?startTime=${startTime}&endTime=${endTime}`).then(it => it.data);
  }

  getBestUsers(startTime: string, endTime: string): Promise<BaseResponse<Array<Rating_BestUser>>> {
    return this.http.get(`/api/rating/getBestUsers?startTime=${startTime}&endTime=${endTime}&count=3`).then(it => it.data);
  }

  getUsergraphicLine(startTime: string, endTime: string): Promise<BaseResponse<Array<Rating_Line>>> {
    return this.http.get(`/api/rating/getUsergraphicLine/all?startTime=${startTime}&endTime=${endTime}`).then(it => it.data);
  }

  getActivities(startTime: string, endTime: string): Promise<BaseResponse<Array<Rating_Activity>>> {
    return this.http.get(`/api/rating/getActivities/all?startTime=${startTime}&endTime=${endTime}`).then(it => it.data);
  }

  getConstructorsTasks(): Promise<BaseResponse<Array<Constructors_Task>>> {
    return this.http.get(`/api/constructor/tasks/getItems`).then(it => it.data);
  }
  updateConstructorsTasks(task: Constructors_Task_Send): Promise<BaseResponse<any>> {
    return this.http.put(`/api/constructor/task/update`, task).then(it => it.data);
  }
  createConstructorsTasks(task: Constructors_Task_Send): Promise<BaseResponse<any>> {
    return this.http.post(`/api/constructor/task/create`, task).then(it => it.data);
  }
  deleteConstructorsTasks(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`/api/constructor/task/delete/${id}`).then(it => it.data);
  }
  updateConstructorsTaskDetail(illness: IIllness): Promise<BaseResponse<IIllness>> {
    return this.http.put(`/api/constructor/task/task_detail/update`, illness).then(it => it.data);
  }
  createConstructorsTaskDetail(illness: IIllness): Promise<BaseResponse<IIllness>> {
    return this.http.post(`/api/constructor/task/task_detail/create`, illness).then(it => it.data);
  }
  getConstructorsTaskDetail(id: number): Promise<BaseResponse<Array<any>>> {
    return this.http.get(`/api/constructor/task/task_detail/get/${id}`).then(it => it.data);
  }
  // deleteConstructorsTaskDetail(id: number): Promise<BaseResponse<any>> {
  //   return this.http.delete(`/api/constructor/task/delete/${id}`).then(it => it.data);
  // }
  getConstructorsCommunications(): Promise<BaseResponse<Array<Constructors_Communication>>> {
    return this.http.get(`/api/communication/getItems`).then(it => it.data);
  }

  updateConstructorsCommunications(id: number, communication: Constructors_Communication): Promise<BaseResponse<any>> {
    return this.http.put(`/api/communication/update/${id}`, communication).then(it => it.data);
  }
  createConstructorsCommunications(communication: Constructors_Communication): Promise<BaseResponse<any>> {
    return this.http.post(`/api/communication/create`, communication).then(it => it.data);
  }
  deleteConstructorsCommunications(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`/api/communication/delete/${id}`).then(it => it.data);
  }
  getConstructorsPatientParams(): Promise<BaseResponse<Array<Constructors_PatientParams>>> {
    return this.http.get(`/api/parametersofresearch/get/all`).then(it => it.data);
  }
  updateConstructorsPatientParams(id: number, params: Constructors_PatientParams): Promise<BaseResponse<any>> {
    return this.http.put(`/api/parametersofresearch/update/${id}`, params).then(it => it.data);
  }
  createConstructorsPatientParams(params: Constructors_PatientParams): Promise<BaseResponse<any>> {
    return this.http.post(`/api/parametersofresearch/create`, params).then(it => it.data);
  }
  deleteConstructorsPatientParams(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`/api/parametersofresearch/delete/${id}`).then(it => it.data);

  }
  getConstructorsClinicalRelevance(): Promise<BaseResponse<Array<Constructors_ClinicalRelevance>>> {
    return this.http.get(`/api/clinicalrelevance`).then(res => res.data);
  }
  updateConstructorsClinicalRelevance(id: number, params: Constructors_ClinicalRelevance): Promise<BaseResponse<any>> {
    return this.http.put(`/api/clinicalrelevance/update/`, params).then(it => it.data);
  }
  createConstructorsClinicalRelevance(params: Constructors_ClinicalRelevance): Promise<BaseResponse<any>> {
    return this.http.post(`/api/clinicalrelevance/create/`, params).then(it => it.data);
  }
  deleteConstructorsClinicalRelevance(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`/api/clinicalrelevance/delete/${id}`).then(it => it.data);
  }


  getConstructorsReferenceDescription(): Promise<BaseResponse<Array<Constructors_ReferenceDescription>>> {
    return this.http.get(`/api/reference_description`).then(res => res.data);
  }
  updateConstructorsReferenceDescription(id: number, params: Constructors_ReferenceDescription): Promise<BaseResponse<any>> {
    return this.http.put(`/api/reference_description/update/`, params).then(it => it.data);
  }
  createConstructorsReferenceDescription(params: Constructors_ReferenceDescription): Promise<BaseResponse<any>> {
    return this.http.post(`/api/reference_description/create/`, params).then(it => it.data);
  }
  deleteConstructorsReferenceDescription(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`/api/reference_description/delete/${id}`).then(it => it.data);
  }

  getConstructorsClinicalDiagnosisAll(): Promise<BaseResponse<Array<Constructors_ClinicalDiagnosis>>> {
    return this.http.get(`/api/clinical_diagnosis/all`).then(res => res.data);
  }
  updateConstructorsClinicalDiagnosis(params: Constructors_ClinicalDiagnosis): Promise<BaseResponse<any>> {
    return this.http.put(`/api/clinical_diagnosis/update`, params).then(it => it.data);
  }
  createConstructorsClinicalDiagnosis(params: Constructors_ClinicalDiagnosis): Promise<BaseResponse<any>> {
    return this.http.post(`/api/clinical_diagnosis/create`, params).then(it => it.data);
  }
  deleteConstructorsClinicalDiagnosis(id: number): Promise<BaseResponse<any>> {
    return this.http.post(`/api/clinical_diagnosis/delete/${id}`).then(it => it.data);
  }

  getConstructorsClinicalDiagnosisMedicalDictionary(id: number): Promise<BaseResponse<Array<Constructors_ClinicalDiagnosisMedicalDictionary>>> {
    return this.http.get(`/api/clinical_diagnosis_medical_dictionary/get_by_clinical_diagnosis/${id}`).then(res => res.data);
  }
  updateConstructorsClinicalDiagnosisMedicalDictionary(params: Constructors_ClinicalDiagnosisMedicalDictionary): Promise<BaseResponse<any>> {
    return this.http.put(`/api/clinical_diagnosis_medical_dictionary/update`, params).then(it => it.data);
  }
  createConstructorsClinicalDiagnosisMedicalDictionary(params: Constructors_ClinicalDiagnosisMedicalDictionary): Promise<BaseResponse<any>> {
    return this.http.post(`/api/clinical_diagnosis_medical_dictionary/create`, params).then(it => it.data);
  }

  getConstructorsClinicalDiagnosisInformationLevel(): Promise<BaseResponse<any>> {
    return this.http.get(`/api/clinical_diagnosis_information_level/all`).then(it => it.data);
  }

  getByTaskConstructorsClinicalDiagnosisInformationLevel(id: number): Promise<BaseResponse<any>> {
    return this.http.get(`/api/clinical_diagnosis_information_level/get_by_task/${id}`).then(it => it.data);
  }


  createConstructorsClinicalDiagnosisInformationLevel(params: Constructors_ClinicalDiagnosisInformationLevel): Promise<BaseResponse<any>> {
    return this.http.post(`/api/clinical_diagnosis_information_level/create`, params).then(it => it.data);
  }

  updateByTaskConstructorsClinicalDiagnosisInformationLevel(params: Constructors_ClinicalDiagnosisInformationLevel): Promise<BaseResponse<any>> {
    return this.http.put(`/api/clinical_diagnosis_information_level/update`, params).then(it => it.data);
  }

  deleteByTaskConstructorsClinicalDiagnosisInformationLevel(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`/api/clinical_diagnosis_information_level/delete/${id}`).then(it => it.data);
  }


  createClinicalDiagnosisTaskProceed(clinicalDiagnosisId: number, typeOfInformationLevelId: number, preliminary_diagnosis?: boolean): Promise<BaseResponse<any>> {
    return this.http.post(`/api/clinical_diagnosis_task_proceed/create/${clinicalDiagnosisId}/${typeOfInformationLevelId}?preliminary_diagnosis=${preliminary_diagnosis}`, {}).then(it => it.data);
  }

  getClinicalDiagnosisTaskProceed(): Promise<BaseResponse<any>> {
    return this.http.get(`/api/clinical_diagnosis_task_proceed/get`).then(it => it.data);
  }

  getConstructorsConnectionWithIllness(): Promise<BaseResponse<Array<Constructors_ConnectionWithIllness>>> {
    return this.http.get(`/api/connectionWithIllness`).then(res => res.data);
  }
  updateConstructorsConnectionWithIllness(id: number, params: Constructors_ConnectionWithIllness): Promise<BaseResponse<any>> {
    return this.http.put(`/api/connectionWithIllness/update/`, params).then(it => it.data);
  }
  createConstructorsConnectionWithIllness(params: Constructors_ConnectionWithIllness): Promise<BaseResponse<any>> {
    return this.http.post(`/api/connectionWithIllness/create/`, params).then(it => it.data);
  }
  deleteConstructorsConnectionWithIllness(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`/api/connectionWithIllness/delete/${id}`).then(it => it.data);
  }

  getConstructorsUrinePressure(): Promise<BaseResponse<Array<Constructors_UrinePressure>>> {
    return this.http.get(`/api/urinepressure`).then(res => res.data);
  }
  updateConstructorsUrinePressure(id: number, params: Constructors_UrinePressure): Promise<BaseResponse<any>> {
    return this.http.put(`/api/urinepressure/update/`, params).then(it => it.data);
  }
  createConstructorsUrinePressure(params: Constructors_UrinePressure): Promise<BaseResponse<any>> {
    return this.http.post(`/api/urinepressure/create/`, params).then(it => it.data);
  }
  deleteConstructorsUrinePressure(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`/api/urinepressure/delete/${id}`).then(it => it.data);
  }

  getConstructorsUrineColor(): Promise<BaseResponse<Array<Constructors_UrineColor>>> {
    return this.http.get(`/api/urinecolor`).then(res => res.data);
  }
  updateConstructorsUrineColor(id: number, params: Constructors_UrineColor): Promise<BaseResponse<any>> {
    return this.http.put(`/api/urinecolor/update/`, params).then(it => it.data);
  }
  createConstructorsUrineColor(params: Constructors_UrineColor): Promise<BaseResponse<any>> {
    return this.http.post(`/api/urinecolor/create/`, params).then(it => it.data);
  }
  deleteConstructorsUrineColor(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`/api/urinecolor/delete/${id}`).then(it => it.data);
  }

  getConstructorsUrineImpurity(): Promise<BaseResponse<Array<Constructors_UrineImpurity>>> {
    return this.http.get(`/api/urineimpurity`).then(res => res.data);
  }
  updateConstructorsUrineImpurity(id: number, params: Constructors_UrineImpurity): Promise<BaseResponse<any>> {
    return this.http.put(`/api/urineimpurity/update/`, params).then(it => it.data);
  }
  createConstructorsUrineImpurity(params: Constructors_UrineImpurity): Promise<BaseResponse<any>> {
    return this.http.post(`/api/urineimpurity/create/`, params).then(it => it.data);
  }
  deleteConstructorsUrineImpurity(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`/api/urineimpurity/delete/${id}`).then(it => it.data);
  }

  getConstructorsUrineSmell(): Promise<BaseResponse<Array<Constructors_UrineImpurity>>> {
    return this.http.get(`/api/urinesmell`).then(res => res.data);
  }
  updateConstructorsUrineSmell(id: number, params: Constructors_UrineImpurity): Promise<BaseResponse<any>> {
    return this.http.put(`/api/urinesmell/update/`, params).then(it => it.data);
  }
  createConstructorsUrineSmell(params: Constructors_UrineImpurity): Promise<BaseResponse<any>> {
    return this.http.post(`/api/urinesmell/create/`, params).then(it => it.data);
  }
  deleteConstructorsUrineSmell(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`/api/urinesmell/delete/${id}`).then(it => it.data);

  }
  getConstructorsUrineTransparency(): Promise<BaseResponse<Array<Constructors_UrineTransparency>>> {
    return this.http.get(`/api/urinetransparency`).then(res => res.data);
  }
  updateConstructorsUrineTransparency(id: number, params: Constructors_UrineTransparency): Promise<BaseResponse<any>> {
    return this.http.put(`/api/urinetransparency/update/`, params).then(it => it.data);
  }
  createConstructorsUrineTransparency(params: Constructors_UrineTransparency): Promise<BaseResponse<any>> {
    return this.http.post(`/api/urinetransparency/create/`, params).then(it => it.data);
  }
  deleteConstructorsUrineTransparency(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`/api/urinetransparency/delete/${id}`).then(it => it.data);
  }

  getConstructorsUrineFoam(): Promise<BaseResponse<Array<Constructors_UrineFoam>>> {
    return this.http.get(`/api/urinefoam`).then(res => res.data);
  }
  updateConstructorsUrineFoam(id: number, params: Constructors_UrineFoam): Promise<BaseResponse<any>> {
    return this.http.put(`/api/urinefoam/update/`, params).then(it => it.data);
  }
  createConstructorsUrineFoam(params: Constructors_UrineFoam): Promise<BaseResponse<any>> {
    return this.http.post(`/api/urinefoam/create/`, params).then(it => it.data);
  }
  deleteConstructorsUrineFoam(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`/api/urinefoam/delete/${id}`).then(it => it.data);
  }

  getConstructorsUrineComfort(): Promise<BaseResponse<Array<Constructors_UrineComfort>>> {
    return this.http.get(`/api/urinecomfort`).then(res => res.data);
  }
  updateConstructorsUrineComfort(id: number, params: Constructors_UrineComfort): Promise<BaseResponse<any>> {
    return this.http.put(`/api/urinecomfort/update/`, params).then(it => it.data);
  }
  createConstructorsUrineComfort(params: Constructors_UrineComfort): Promise<BaseResponse<any>> {
    return this.http.post(`/api/urinecomfort/create/`, params).then(it => it.data);
  }
  deleteConstructorsUrineComfort(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`/api/urinecomfort/delete/${id}`).then(it => it.data);
  }

  getConstructorsUrineAdditionalFenomen(): Promise<BaseResponse<Array<Constructors_UrineAdditionalFenomen>>> {
    return this.http.get(`/api/urineadditionalfenomen`).then(res => res.data);
  }
  updateConstructorsUrineAdditionalFenomen(id: number, params: Constructors_UrineAdditionalFenomen): Promise<BaseResponse<any>> {
    return this.http.put(`/api/urineadditionalfenomen/update/`, params).then(it => it.data);
  }
  createConstructorsUrineAdditionalFenomen(params: Constructors_UrineAdditionalFenomen): Promise<BaseResponse<any>> {
    return this.http.post(`/api/urineadditionalfenomen/create/`, params).then(it => it.data);
  }
  deleteConstructorsUrineAdditionalFenomen(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`/api/urineadditionalfenomen/delete/${id}`).then(it => it.data);
  }

  getConstructorsExcrementColor(): Promise<BaseResponse<Array<Constructors_ExcrementColor>>> {
    return this.http.get(`/api/excrementcolor`).then(res => res.data);
  }
  updateConstructorsExcrementColor(id: number, params: Constructors_ExcrementColor): Promise<BaseResponse<any>> {
    return this.http.put(`/api/excrementcolor/update/`, params).then(it => it.data);
  }
  createConstructorsExcrementColor(params: Constructors_ExcrementColor): Promise<BaseResponse<any>> {
    return this.http.post(`/api/excrementcolor/create/`, params).then(it => it.data);
  }
  deleteConstructorsExcrementColor(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`/api/excrementcolor/delete/${id}`).then(it => it.data);
  }

  getConstructorsExcrementComfort(): Promise<BaseResponse<Array<Constructors_ExcrementComfort>>> {
    return this.http.get(`/api/excrementcomfort`).then(res => res.data);
  }
  updateConstructorsExcrementComfort(id: number, params: Constructors_ExcrementComfort): Promise<BaseResponse<any>> {
    return this.http.put(`/api/excrementcomfort/update/`, params).then(it => it.data);
  }
  createConstructorsExcrementComfort(params: Constructors_ExcrementComfort): Promise<BaseResponse<any>> {
    return this.http.post(`/api/excrementcomfort/create/`, params).then(it => it.data);
  }
  deleteConstructorsExcrementComfort(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`/api/excrementcomfort/delete/${id}`).then(it => it.data);
  }

  getConstructorsExcrementPhoto(): Promise<BaseResponse<Array<Constructors_ExcrementPhoto>>> {
    return this.http.get(`/api/excrementphoto`).then(res => res.data);
  }
  updateConstructorsExcrementPhoto(id: number, params: Constructors_ExcrementPhoto): Promise<BaseResponse<any>> {
    return this.http.put(`/api/excrementphoto/update/`, params).then(it => it.data);
  }
  createConstructorsExcrementPhoto(params: Constructors_ExcrementPhoto): Promise<BaseResponse<any>> {
    return this.http.post(`/api/excrementphoto/create/`, params).then(it => it.data);
  }
  deleteConstructorsExcrementPhoto(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`/api/excrementphoto/delete/${id}`).then(it => it.data);
  }

  getConstructorsExcrementSmell(): Promise<BaseResponse<Array<Constructors_ExcrementSmell>>> {
    return this.http.get(`/api/excrementsmell`).then(res => res.data);
  }
  updateConstructorsExcrementSmell(id: number, params: Constructors_ExcrementSmell): Promise<BaseResponse<any>> {
    return this.http.put(`/api/excrementsmell/update/`, params).then(it => it.data);
  }
  createConstructorsExcrementSmell(params: Constructors_ExcrementSmell): Promise<BaseResponse<any>> {
    return this.http.post(`/api/excrementsmell/create/`, params).then(it => it.data);
  }
  deleteConstructorsExcrementSmell(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`/api/excrementsmell/delete/${id}`).then(it => it.data);
  }

  getConstructorsInspectionside(): Promise<BaseResponse<Array<any>>> {
    return this.http.get(`/api/inspectionside`).then(it => it.data);
  }
  getConstructorsInspectionsideByTask(inspectionMethod: string, partOfBody: string): Promise<BaseResponse<Array<any>>> {
    return this.http.get(`/api/inspectionside/by_task/${inspectionMethod}/${partOfBody}`).then(it => it.data);
  }
  getConstructorsPartOfBody(): Promise<BaseResponse<Array<any>>> {
    return this.http.get(`/api/partofbody`).then(it => it.data);
  }
  getConstructorsInspectionMethods(): Promise<BaseResponse<Array<any>>> {
    return this.http.get(`/api/inspectionmethods`).then(it => it.data);
  }
  getConstructorsPointsOfView(taskId: number): Promise<BaseResponse<Array<PointOfView_GetResponse_NetData>>> {
    return this.http.get(`/api/pointsofview/get/task/${taskId}`).then(it => it.data);
  }
  getUserTaskProceedJoint(): Promise<BaseResponse<any>> {
    return this.http.get(`/api/user_task_proceed_joint`).then(it => it.data);
  }
  createRheumatologyState(body: Rheumatology_State): Promise<BaseResponse<any>> {
    return this.http.post(`/api/rheumatology_state/create_detail`, body).then(it => it.data);
  }
  updateRheumatologyState(body: Rheumatology_State): Promise<BaseResponse<any>> {
    return this.http.put(`/api/rheumatology_state/update_detail`, body).then(it => it.data);
  }
  deleteRheumatologyState(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`/api/rheumatology_state/delete_detail/${id}`).then(it => it.data);
  }
  getRheumatologyState(id: number): Promise<BaseResponse<any>> {
    return this.http.get(`/api/rheumatology_state/get_task_detail/${id}`).then(it => it.data);
  }
  createConstructorsPointsOfView(pointsOfView: PointsOfView_Props): Promise<BaseResponse<Array<any>>> {
    return this.http.post(`/api/pointsofview/create`, pointsOfView).then(it => it.data);
  }
  getRheumatologyUserTaskProceedState(): Promise<BaseResponse<any>> {
    return this.http.get(`/api/rheumatology_state/usertaskproceed_rheumatology_state`).then(it => it.data);
  }
  createRheumatologyUserTaskProceedState(state: number): Promise<BaseResponse<Array<any>>> {
    return this.http.post(`/api/rheumatology_state/usertaskproceed_rheumatology_state/${state}`, state).then(it => it.data);
  }
  updateConstructorsPointsOfView(pointsOfView: PointsOfView_Props): Promise<BaseResponse<Array<any>>> {
    return this.http.put(`/api/pointsofview/update/${pointsOfView.id}`, pointsOfView).then(it => it.data);
  }
  deleteConstructorsPointsOfView(id: number): Promise<BaseResponse<Array<any>>> {
    return this.http.delete(`/api/pointsofview/delete/${id}`).then(it => it.data);
  }
  createCustomPointOfView(pointOfView: PointsOfView_Props): Promise<BaseResponse<Array<any>>> {
    return this.http.post(`/api/constructor/inspection/create`, pointOfView).then(res => res.data);
  }
  updateCustomPointOfView(pointOfView: PointsOfView_Props): Promise<BaseResponse<Array<any>>> {
    return this.http.put(`/api/constructor/inspection/update`, pointOfView).then(res => res.data);
  }
  deleteCustomPointOfView(id: number): Promise<BaseResponse<Array<any>>> {
    return this.http.delete(`/api/constructor/inspection/delete/${id}`).then(res => res.data);
  }
  getConstructorsBodyPartImage(id: number): Promise<BaseResponse<Array<any>>> {
    return this.http.get(`/api/bodypartimage/get/${id}`).then(it => it.data);
  }
  createConstructorsBodyPartImage(bodyPart: BodyPartImage_Props): Promise<BaseResponse<Array<any>>> {
    return this.http.post(`/api/bodypartimage/create`, bodyPart).then(it => it.data);
  }
  updateConstructorsBodyPartImage(bodyPart: BodyPartImage_Props): Promise<BaseResponse<Array<any>>> {
    return this.http.put(`/api/bodypartimage/update/${bodyPart.id}`, bodyPart).then(it => it.data);
  }
  deleteConstructorsBodyPartImage(id: number): Promise<BaseResponse<Array<any>>> {
    return this.http.delete(`/api/bodypartimage/delete/${id}`).then(it => it.data);
  }
  // createConstructorsBodyPartImage(bodyPart: BodyPartImage_Props): Promise<BaseResponse<Array<any>>> {
  //   return this.http
  //     .post(`services/cyberdoctor-anamnesis-microservice/api/bodypartimage/create`, bodyPart)
  //     .then(it => it.data);
  // }
  // updateConstructorsBodyPartImage(bodyPart: BodyPartImage_Props): Promise<BaseResponse<Array<any>>> {
  //   return this.http
  //     .put(`services/cyberdoctor-anamnesis-microservice/api/bodypartimage/update/${bodyPart.id}`, bodyPart)
  //     .then(it => it.data);
  // }
  // deleteConstructorsBodyPartImage(id: number): Promise<BaseResponse<Array<any>>> {
  //   return this.http
  //     .delete(`services/cyberdoctor-anamnesis-microservice/api/bodypartimage/delete/${id}`)
  //     .then(it => it.data);
  // }
  getConstructorsTypeOfResearch(): Promise<BaseResponse<Array<Constructors_Additional>>> {
    return this.http.get(`/api/type-of-research`).then(it => it.data);
  }
  getFrontBodyPartImage(partOfBodyId: undefined | string, inspectionMethodId: string | undefined, id: string | undefined): Promise<BaseResponse<Array<get_Front_Body_Part_Image>>> {
    return this.http.get(`api/bodypartimage/front/${partOfBodyId}/${inspectionMethodId}/${id}`).then(res => res.data);
  }

  getConstructorsDescriptionList(id: number): Promise<BaseResponse<Type_DescriptionList>> {
    return this.http.get(`/api/researchdescription/get/${id}`).then(it => it.data);
  }
  createConstructorsDescriptionList(body: Type_DescriptionList): Promise<BaseResponse<Type_DescriptionList>> {
    return this.http.post(`/api/researchdescription/create`, body).then(it => it.data);
  }
  updateConstructorsDescriptionList(id: number, body: Type_DescriptionList): Promise<BaseResponse<Type_DescriptionList>> {
    return this.http.put(`/api/researchdescription/update/${id}`, body).then(it => it.data);
  }
  deleteConstructorsDescriptionList(id: number): Promise<BaseResponse<Type_DescriptionList>> {
    return this.http.delete(`/api/researchdescription/delete/${id}`).then(it => it.data);
  }

  getResearchUnit(): Promise<BaseResponse<Array<Type_ResearchUnit>>> {
    return this.http.get(`/api/researchunit`).then(it => it.data);
  }

  getResearchDefault(id: number): Promise<BaseResponse<any>> {
    return this.http.get(`/api/researchdefault/get/${id}`).then(it => it.data);
  }
  createResearchDefault(body: any): Promise<BaseResponse<any>> {
    return this.http.post(`/api/researchdefault/create`, body).then(it => it.data);
  }
  updateResearchDefault(id: number, body: any): Promise<BaseResponse<any>> {
    return this.http.put(`/api/researchdefault/update/${id}`, body).then(it => it.data);
  }
  createParametersForDefaultResearch(body: any): Promise<BaseResponse<any>> {
    return this.http.post(`/api/parametersfordefaultresearch/create`, body).then(it => it.data);
  }
  deleteParametersForDefaultResearch(id: any): Promise<BaseResponse<any>> {
    return this.http.delete(`/api/parametersfordefaultresearch/delete/${id}`).then(it => it.data);
  }
  getParametersForDefaultResearch(id: number): Promise<BaseResponse<Array<Type_ParametersForDefaultResearch>>> {
    return this.http.get(`/api/parametersfordefaultresearch/get/${id}`).then(it => it.data);
  }
  // /api/researchdefault/getbytypeid/8
  getResearchDefaultByTypeId(id: number): Promise<BaseResponse<Array<Type_ResearchDefaultByTypeId>>> {
    return this.http.get(`/api/parametersfordefaultresearch/get/${id}`).then(it => it.data);
  }

  updateConstructorsTypeOfResearch(id: number, typeOfResearch: Constructors_Additional): Promise<BaseResponse<any>> {
    return this.http.put(`/api/type-of-research/update/${id}`, typeOfResearch).then(it => it.data);
  }
  createConstructorsTypeOfResearch(typeOfResearch: Constructors_Additional): Promise<BaseResponse<any>> {
    return this.http.post(`/api/type-of-research/create`, typeOfResearch).then(it => it.data);
  }
  deleteConstructorsTypeOfResearch(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`/api/type-of-research/delete/${id}`).then(it => it.data);
  }
  getConstructorsGroupTypeResearch(id: number): Promise<BaseResponse<Array<any>>> {
    return this.http.get(`/api/group-type-research/get/${id}`).then(it => it.data);
  }
  getConstructorsDiagnose(): Promise<BaseResponse<Array<any>>> {
    return this.http.get(`/api/constructor/diagnose/getItems`).then(it => it.data);
  }
  getConstructorsMedication(): Promise<BaseResponse<Array<any>>> {
    return this.http.get(`/api/standart_medication`).then(it => it.data);
  }
  getConstructorsNonMedication(): Promise<BaseResponse<Array<any>>> {
    return this.http.get(`/api/standart_alternative_medication`).then(it => it.data);
  }
  getConstructorsObjectiveStatus(): Promise<BaseResponse<Array<any>>> {
    return this.http.get(`/api/constructor/objectivestatus/getItems`).then(it => it.data);
  }
  getUniversityLevel(): Promise<BaseResponse<Array<University_Level>>> {
    return this.http.get(`/api/university-level`).then(it => it.data);
  }
  getUniversityLevelAll(): Promise<BaseResponse<Array<University_Level>>> {
    return this.http.get(`/api/university-level/all`).then(it => it.data);
  }
  getPatientModel(): Promise<BaseResponse<Array<Patient_Model>>> {
    return this.http.get(`/api/patientmodel`).then(it => it.data);
  }
  getTypeOfPatientStatus(): Promise<BaseResponse<Array<Type_Of_Patient_Status>>> {
    return this.http.get(`/api/type-of-patient-status`).then(it => it.data);
  }

  getDietMedicationsResults(): Promise<BaseResponse<MedicalResult_ResponseData>> {
    return this.http.get(`/api/standartalternativemedication/result/diet`).then(it => it.data);
  }

  getFoodMedicationsResults(): Promise<BaseResponse<MedicalResult_ResponseData>> {
    return this.http.get(`/api/standartalternativemedication/result/food`).then(it => it.data);
  }

  getScheduleMedicationsResults(): Promise<BaseResponse<MedicalResult_ResponseData>> {
    return this.http.get(`/api/standartalternativemedication/result/schedule`).then(it => it.data);
  }

  getActiveMedicationsResults(): Promise<BaseResponse<MedicalResult_ResponseData>> {
    return this.http.get(`/api/standartalternativemedication/result/active`).then(it => it.data);
  }

  getPassiveMedicationsResults(): Promise<BaseResponse<MedicalResult_ResponseData>> {
    return this.http.get(`/api/standartalternativemedication/result/passive`).then(it => it.data);
  }

  getAlternativeTreatment(): Promise<BaseResponse<PassiveActiveTreatment_GetResponse_NetData[]>> {
    return this.http.get(`/api/alterntivetreatment`).then(it => it.data);
  }

  getPathophysiologicalModel(id: number): Promise<BaseResponse<any>> {
    return this.http.get(`/api/pathophysiological_model/get_by_taskid/${id}`).then(it => it.data);
  }
  setPathophysiologicalModel(id: number): Promise<BaseResponse<any>> {
    return this.http.get(`/api/pathophysiological_model/get/${id}`).then(it => it.data);
  }

  getReferralSpecialist(): Promise<BaseResponse<any>> {
    return this.http.get(`/api/referralspecialist`).then(it => it.data);
  }
  getSurgeons(): Promise<BaseResponse<any>> {
    return this.http.get(`/api/surgeon_treatment/get_all`).then(it => it.data);
  }

  getTreatmentSchemeTask(): Promise<BaseResponse<any>> {
    return this.http.get(`/api/treatment/getTreatmentSchemeTask`).then(it => it.data);
  }

  getDescription(page: string): Promise<BaseResponse<any>> {
    return this.http.get(`/api/pageDescription/get_description?page=${page}`).then(it => it.data);
  }

  getSettingsPiloting(): Promise<BaseResponse<any>> {
    return this.http.get(`/api/settings/piloting`).then(it => it.data);
  }

  getSettingsType(): Promise<BaseResponse<any>> {
    return this.http.get(`/api/settings/type_program`).then(it => it.data);
  }


  createReferralSpecialist(name: string): Promise<BaseResponse<any>> {
    return this.http
      .post(`/api/alterativemedication/create/referralspecialist`, {
        name,
      })
      .then(it => it.data);
  }
  getCurrentReferralSpecialist(name: string): Promise<BaseResponse<any>> {
    return this.http.get(`/api/alterativemedication/gettaskid/referralspecialist`).then(it => it.data);
  }

  createReferralSurgeon(name: string): Promise<BaseResponse<any>> {
    return this.http
      .post(`/api/surgeon_treatment/create`, {
        name,
      })
      .then(it => it.data);
  }

  getCurrentSurgeon(name: string): Promise<BaseResponse<any>> {
    return this.http.get(`/api/alterativemedication/gettaskid/surgeon_treatment`).then(it => it.data);
  }

  sendUsersFeedback(text: string, topic: string, email: string) {
    return this.http
      .post(`/api/sending_mail/sent`, {
        text,
        topic,
        email
      })
      .then(it => it.data);
  }

  getIllnestaskDetail(task_id: number): Promise<BaseResponse<any>> {
    return this.http.get(`/api/task_detail/illness/${task_id}`).then(it => it.data);
  }

  getStudentsGroupOrganization(id: string | number): Promise<BaseResponse<any>> {
    return this.http.get(`/api/students_group/organization?id=${id}`).then(it => it.data)
  }

  getStudentsGroupTask(id: string | number): Promise<BaseResponse<any>> {
    return this.http.get(`/api/student_group_task`).then(it => it.data)
  }

  getAllTaskOrganization(): Promise<BaseResponse<any>> {
    return this.http.get(`/api/organization/get`).then(it => it.data)
  }
  getOrganizationByTask(id: number): Promise<BaseResponse<any>> {
    return this.http.get(`/api/task_organization/get_by_task/${id}`).then(it => it.data)
  }

  deleteOrganizationByTask(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`/api/task_organization/delete/${id}`).then(it => it.data)
  }

  createOrganizationByTask(organizationId: number, taskId: number): Promise<BaseResponse<any>> {
    return this.http.post(`/api/task_organization/create`, { organizationId, taskId }).then(it => it.data)
  }

  getAllTaskTrainer(): Promise<BaseResponse<any>> {
    return this.http.get(`/api/trainer/get`).then(it => it.data)
  }

  getTrainerByTask(id: number): Promise<BaseResponse<any>> {
    return this.http.get(`/api/task_trainer/get_by_task/${id}`).then(it => it.data)
  }

  deleteTrainerByTask(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`/api/task_trainer/delete/${id}`).then(it => it.data)
  }

  createTrainerByTask(taskId: number, trainerId: number): Promise<BaseResponse<any>> {
    return this.http.post(`/api/task_trainer/create`, { taskId, trainerId }).then(it => it.data)
  }

  getAllSpecialityStudent(): Promise<BaseResponse<any>> {
    return this.http.get(`/api/student_speciality`).then(it => it.data)
  }

  getSpecialityStudentByTask(id: number): Promise<BaseResponse<any>> {
    return this.http.get(`/api/task_student_speciality/get_by_task/${id}`).then(it => it.data)
  }
  deleteSpecialityStudentByTask(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`/api/task_student_speciality/delete/${id}`).then(it => it.data)
  }

  createSpecialityByTask(studentSpecialityId: number, taskId: number): Promise<BaseResponse<any>> {
    return this.http.post(`/api/task_student_speciality/create`, { studentSpecialityId, taskId }).then(it => it.data)
  }

  getTaskDetailMedia(id: number): Promise<BaseResponse<any>> {
    return this.http.get(`/api/task_detail/media/${id}`).then(it => it.data)
  }

  /////////////////////////////////////
  getConstructorPastResearch(taskId: number): Promise<BaseResponse<any>> {
    return this.http.get(`api/constructor/research/get_by_task_with_information_level/{${taskId}`).then(res => res.data);
  }
  getConstructorPastSpecialist(taskId: number): Promise<BaseResponse<any>> {
    return this.http.get(`api/constructor/specialist_advice/get_by_task_with_information_level/{${taskId}`).then(res => res.data);
  }
  updateConstructorPastResearch(id: number):Promise<BaseResponse<any>> {
    return this.http.put(`api/constructor/research/research_3_11/${id}`).then(res => res.data);
  }

  updateConstructorPastSpecialist(id: number): Promise<BaseResponse<any>> {
    return this.http.put(`api/constructor/specialist_advice/specialist_9_12/${id}`).then(res => res.data);
  }

  getTaskProceedPastResearch(): Promise<BaseResponse<any>> {
    return this.http.get(`api/task-proceed/research`).then(res => res.data);
  }
  getTaskProceedPastSpecialist(): Promise<BaseResponse<any>> {
    return this.http.get(`api/task-proceed/specialist`).then(res => res.data);
  }

  getConstructorAdditionalResults(id: number): Promise<BaseResponse<Array<get_Constructor_Additional_Results>>> {
    return this.http.get(`api/constructor/research/get/${id}`).then(res => res.data);
  }

  updateConstructorReferenceValue(body: Constructor_Reference): Promise<BaseResponse<any>> {
    return this.http.post(`api/constructor/research/reference`, body)
  }

  updateConstructorAdditionalResult(body: Update_Research_Instrument): Promise<BaseResponse<Update_Research_Instrument>> {
    return this.http.put(`api/constructor/research/update`, body).then(res => res.data);
  }

  deleteConstructorAdditionalResult(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`api/constructor/research/delete/${id}`).then(res => res.data);
  }

  getReference(): Promise<BaseResponse<Array<get_Reference>>> {
    return this.http.get(`api/reference_description`).then(res => res.data);
  }

  createActiveMedication(data: ActiveMedication_CreateUpdate_NetData): Promise<BaseResponse<void>> {
    return this.http.post(`/api/alterativemedication/create/alternativemedictiontype/active`, data).then(it => it.data);
  }
  updateActiveMedication(id: number, data: ActiveMedication_CreateUpdate_NetData): Promise<BaseResponse<void>> {
    return this.http.put(`/api/alterativemedication/update/${id}`, data).then(it => it.data);
  }
  createPassiveMedication(data: PassiveMedication_CreateUpdate_NetData): Promise<BaseResponse<void>> {
    return this.http.post(`/api/alterativemedication/create/alternativemedictiontype/passive`, data).then(it => it.data);
  }
  updatePassiveMedication(id: number, data: PassiveMedication_CreateUpdate_NetData): Promise<BaseResponse<void>> {
    return this.http.put(`/api/alterativemedication/update/${id}`, data).then(it => it.data);
  }

  getActiveMedications(): Promise<BaseResponse<Array<ActiveMedication_GetResponse_NetData>>> {
    return this.http.get(`/api/alterativemedication/gettaskid/alternativemedictiontype/active`).then(it => it.data);
  }

  getPassiveMedications(): Promise<BaseResponse<Array<PassiveMedication_GetResponse_NetData>>> {
    return this.http.get(`/api/alterativemedication/gettaskid/alternativemedictiontype/passive`).then(it => it.data);
  }

  getAlternativeMedicationType(): Promise<BaseResponse<Array<AlternativeMedicationType_GetResponse>>> {
    return this.http.get(`/api/alternativemedicationtype`).then(it => it.data);
  }

  createFoodMedication(data: FoodMedication_CreateUpdate_NetData): Promise<BaseResponse<void>> {
    return this.http.post(`/api/alterativemedication/create/food`, data).then(it => it.data);
  }

  updateFoodMedication(id: number, data: FoodMedication_CreateUpdate_NetData): Promise<BaseResponse<void>> {
    return this.http.put(`/api/alterativemedication/update/${id}`, data).then(it => it.data);
  }

  getFoodMedicationsItems(): Promise<BaseResponse<Array<FoodMedication_GetResponse_NetData>>> {
    return this.http.get(`/api/alterativemedication/gettaskid/food`).then(it => it.data);
  }

  getTargetUseTypes(): Promise<BaseResponse<Array<TargetUseType_NetData>>> {
    return this.http.get(`/api/targetuse`).then(it => it.data);
  }

  updateDietMedication(id: number, data: DietMedication_CreateUpdateRequest_NetData): Promise<BaseResponse<void>> {
    return this.http.put(`/api/alterativemedication/update/${id}`, data).then(it => it.data);
  }

  deleteDietMedication(id: number): Promise<BaseResponse<void>> {
    return this.http.delete(`/api/alterativemedication/delete/${id}`).then(it => it.data);
  }


  getDietMedicalItems(): Promise<BaseResponse<DietMedication_GetResponse_NetData[]>> {
    return this.http.get(`/api/alterativemedication/gettaskid/diet`).then(it => it.data);
  }

  updateScheduleMedication(id: number, data: ScheduleMedication_CreateUpdate_NetData): Promise<BaseResponse<void>> {
    return this.http.put(`/api/alterativemedication/update/${id}`, data).then(it => it.data);
  }

  createScheduleMedication(data: ScheduleMedication_CreateUpdate_NetData): Promise<BaseResponse<void>> {
    return this.http.post(`/api/alterativemedication/create/schedule`, data).then(it => it.data);
  }

  getScheduleMedicationItems(): Promise<BaseResponse<ScheduleMedication_GetResponse_NetData[]>> {
    return this.http.get(`/api/alterativemedication/gettaskid/schedule`).then(it => it.data);
  }

  createDietMedication(diet: DietMedication_CreateUpdateRequest_NetData): Promise<BaseResponse<void>> {
    return this.http.post(`/api/alterativemedication/create/diet`, diet).then(it => it.data);
  }

  deleteDietInConstructorTask(id: number): Promise<BaseResponse<number>> {
    return this.http.delete(`/api/alterativemedication/delete/${id}`).then(data => data.data)
  }

  increaseToTherapyTaskStage(): Promise<BaseResponse<any>> {
    return this.http.post(`/api/task-proceed/to_healing`).then(it => it.data);
  }

  getNotMedicalMedication(taskProceedId: number): Promise<BaseResponse<Array<NotMedicalMedication_NetData>>> {
    return this.http.get(`/api/prescribedmodeandfood/gettaskid/${taskProceedId}`).then(it => it.data);
  }

  createNotMedicalMedication(medication: NotMedicalMedication_NetData): Promise<BaseResponse<any>> {
    return this.http.post(`/api/prescribedmodeandfood/create`, medication).then(it => it.data);
  }
  editNotMedicalMedication(id: number, medication: NotMedicalMedication_NetData): Promise<BaseResponse<any>> {
    return this.http.post(`/api/prescribedmodeandfood/update/${id}`, medication).then(it => it.data);
  }

  getNotMedicalFlags(): Promise<BaseResponse<Array<NotMedicalFlag_ResponseData>>> {
    return this.http.get(`/api/prescribedmodeandfood/flag`).then(it => it.data);
  }

  getNotMedicalTypes(): Promise<BaseResponse<Array<NotMedicalType_ResponseData>>> {
    return this.http.get(`/api/prescribedmodeandfood/type`).then(it => it.data);
  }

  getDietTypes(): Promise<BaseResponse<Array<DietType_ResponseData>>> {
    return this.http.get(`/api/diet`).then(it => it.data);
  }

  getDosageUnits(): Promise<BaseResponse<Array<DosageUnit_ResponseData>>> {
    return this.http.get(`/api/dosageunit`).then(it => it.data);
  }

  getMedicalResult(): Promise<BaseResponse<MedicalResult_ResponseData>> {
    return this.http.get(`/api/medicationresult`).then(it => it.data);
  }

  getNotMedicalResult(): Promise<BaseResponse<PrescribedMedicationResult__ResponseData[]>> {
    return this.http.get(`/api/modeandfoodresult`).then(it => it.data);
  }

  createPrescribedMedication(medication: PrescribedMedication__NetData): Promise<BaseResponse<any>> {
    return this.http.post(`/api/prescribedmedication/create`, medication).then(it => it.data);
  }

  editPrescribedMedication(id: number, medication: PrescribedMedication__NetData): Promise<BaseResponse<any>> {
    return this.http.put(`/api/prescribedmedication/update/${id}`, medication).then(it => it.data);
  }

  getPrescribedMedicationByTaskId(taskProceedId: number): Promise<BaseResponse<Array<PrescribedMedication__ResponseData>>> {
    return this.http.get(`/api/prescribedmedication/gettaskid/${taskProceedId}`).then(it => it.data);
  }

  getReceptionPeriod(): Promise<BaseResponse<Array<ReceptionPeriod_NetData>>> {
    return this.http.get(`/api/receptionperiod`).then(it => it.data);
  }

  getAdditionalRestriction(): Promise<BaseResponse<Array<AdditionalRestriction>>> {
    return this.http.get(`/api/additionalrestriction`).then(it => it.data);
  }

  getConnectionWithSleep(): Promise<BaseResponse<Array<ConnectionWithSleep>>> {
    return this.http.get(`/api/connectionwithsleep`).then(it => it.data);
  }

  getConnectionWithFoods(): Promise<BaseResponse<Array<ConnectionWithFood>>> {
    return this.http.get(`/api/connectionwithfood`).then(it => it.data);
  }

  getTimesOfReceipt(): Promise<BaseResponse<Array<TimeOfReceipt>>> {
    return this.http.get(`/api/timeofreceipt`).then(it => it.data);
  }

  getReceptionMethods(): Promise<BaseResponse<Array<ReceptionMethod>>> {
    return this.http.get(`/api/receptionmethod`).then(it => it.data);
  }

  getGroupOfMedicine(): Promise<BaseResponse<Array<GroupOfMedicine>>> {
    return this.http.get(`/api/groupofmedicine`).then(it => it.data);
  }

  getReleaseForms(): Promise<BaseResponse<Array<ReleaseForm>>> {
    return this.http.get(`/api/releaseform`).then(it => it.data);
  }

  getLocalisationsPlace(): Promise<BaseResponse<Array<get_localisations_place>>> {
    return this.http.get(`/api/localisations`).then(it => it.data);
  }
  getStandardAlternativeMedication(idTask: number, method: number): Promise<BaseResponse<Array<get_Standart_Alternative_Medication>>> {
    return this.http.get(`/api/constructor/standart_alternative_medication/get_by_task/${idTask}/${method}`).then(res => res.data);
  }
  getStandardMedication(id: number): Promise<BaseResponse<Array<get_Medical_Treatment>>> {
    return this.http.get(`/api/constructor/standart_medication/get/${id}`).then(res => res.data);
  }
  getMedicalDictionaryAll(): Promise<BaseResponse<Array<get_Medical_Dictionary_All>>> {
    return this.http.get(`/api/medical-dictionary/all?page=0&size=20000`).then(res => res.data);
  }
  getMedicalDictionaryFromId(id: number): Promise<BaseResponse<get_Medical_Dictionary>> {
    return this.http.get(`api/medical-dictionary/find/${id}`).then(res => res.data);
  }
  getMedicationDictionaryByText(text: string): Promise<BaseResponse<Array<get_Medical_Dictionary>>> {
    return this.http.get(`/api/medical-dictionary/by_text?text=${text}`, {params: { pageSize: 50000 }}).then(res => res.data);
  }
  createDiet(diet: Add_Diet): Promise<BaseResponse<Add_Diet>> {
    return this.http.post(`api/constructor/standart_alternative_medication/create/diet`, diet).then(res => res.data);
  }
  updateDiet(diet: Add_Diet): Promise<BaseResponse<Add_Diet>> {
    return this.http.put(`api/constructor/standart_alternative_medication/update/diet`, diet).then(res => res.data);
  }
  deleteDiet(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`api/constructor/standart_alternative_medication/delete/${id}`).then(res => res.data);
  }

  createSchedule(schedule: ScheduleMedication_CreateUpdate_NetData): Promise<BaseResponse<ScheduleMedication_CreateUpdate_NetData>> {
    return this.http.post(`/api/constructor/standart_alternative_medication/create/schedule`, schedule).then(res => res.data);
  }
  updateSchedule(schedule: ScheduleMedication_CreateUpdate_NetData): Promise<BaseResponse<Create_Or_Update_Schedule>> {
    return this.http.put(`/api/constructor/standart_alternative_medication/update/schedule`, schedule).then(res => res.data);
  }
  deleteSchedule(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`/api/constructor/standart_alternative_medication/delete/${id}`).then(res => res.data);
  }

  createNotTreatmentPassive(treatment: CreateOrUpdateNotTreatment): Promise<BaseResponse<CreateOrUpdateNotTreatment>> {
    return this.http.post(`api/constructor/standart_alternative_medication/create/passive`, treatment).then(res => res.data);
  }
  updateNotTreatmentPassive(treatment: CreateOrUpdateNotTreatment): Promise<BaseResponse<CreateOrUpdateNotTreatment>> {
    return this.http.put(`api/constructor/standart_alternative_medication/update/passive`, treatment).then(res => res.data);
  }
  deleteNotTreatmentPassive(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`api/constructor/standart_alternative_medication/delete/${id}`).then(res => res.data);
  }
  createNotTreatmentActive(treatment: CreateOrUpdateNotTreatment): Promise<BaseResponse<CreateOrUpdateNotTreatment>> {
    return this.http.post(`api/constructor/standart_alternative_medication/create/active`, treatment).then(res => res.data);
  }
  updateNotTreatmentActive(treatment: CreateOrUpdateNotTreatment): Promise<BaseResponse<CreateOrUpdateNotTreatment>> {
    return this.http.put(`api/constructor/standart_alternative_medication/update/active`, treatment).then(res => res.data);
  }
  deleteNotTreatmentActive(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`api/constructor/standart_alternative_medication/delete/${id}`).then(res => res.data);
  }
  createMedicationTreatment(treatment: Treatment_Item_Type): Promise<BaseResponse<Treatment_Item_Type>> {
    return this.http.post(`api/constructor/standart_medication/create`, treatment).then(res => res.data);
  }
  updateMedicationTreatment(treatment: Treatment_Item_Type): Promise<BaseResponse<Treatment_Item_Type>> {
    return this.http.put(`api/constructor/standart_medication/update`, treatment).then(res => res.data);
  }
  deleteMedicationTreatment(id: number): Promise<BaseResponse<Treatment_Item_Type>> {
    return this.http.delete(`api/constructor/standart_medication/delete/${id}`).then(res => res.data);
  }

  searchResearch(groupId: number, text: string): Promise<BaseResponse<TypeOfResearchSearchResult_Response>> {
    const result = this.http
      .get(`/api/type-of-research/filtered`, {
        params: { groupId: groupId, text: text },
      })
      .then(it => it.data)
      .then((it: BaseResponse<TypeOfResearchSearchResult_Response>) => {
        // манкипатчим результаты поиска приводя один объект со списком айди родительских групп к нескольким объектам типов ресерчей с полем parentGroupId
        // console.log('it search: ', it);

        it.result.typeOfResearches = it.result.typeOfResearches.flatMap((res: any) => {
          const groups = [res.groupsOfResearchIds[0]];

          return groups.map((parentGroupId: number) => {
            return {
              ...res,
              parentGroupId: parentGroupId,
            };
          });
        });
        return it;
      });
    // console.log('result: ', result);

    return result;
  }

  getDiagnosisForms(): Promise<BaseResponse<DiagnosisFrom_ResponseData>> {
    return this.http.get(`/api/diagnosis`).then(it => it.data);
  }

  increaseTaskStage(): Promise<BaseResponse<any>> {
    return this.http.post(`/api/task-proceed/increase-stage`, {}).then(it => it.data);
  }
  increaseSetTaskStage(id: any): Promise<BaseResponse<any>> {
    return this.http.post(`/api/task-proceed/set-stage?stage=${id}`, { stage: id }).then(data => data.data)
  }

  pushDisgnosisResult(reqData: Diagnosis_ReqData): Promise<BaseResponse<any>> {
    return this.http.post(`/api/diagnosis`, reqData).then(it => it.data);
  }

  getDiagnosisTypes(): Promise<BaseResponse<Array<DiagnosisTypes_ResponseData>>> {
    return this.http.get(`/api/context/diagnosis_option`).then(it => it.data);
  }

  getTaskResults(): Promise<BaseResponse<TaskResult_ResponseData>> {
    return this.http.get(`/api/testResult`).then(it => it.data);
  }

  getCurrrentTaskResearchResults(): Promise<BaseResponse<ResearchResults_ResponseData[]>> {
    return this.http.get(`/api/research/get-current-researches`).then(it => it.data);
  }

  getGroupOfResearchWithTypes(groupId: number): Promise<BaseResponse<GroupOfResearch_ResponseData>> {
    return this.http
      .get(`/api/group-of-research/with-types/${groupId}`)
      .then(it => it.data)
      .then((it: BaseResponse<GroupOfResearch_ResponseData>) => {
        // манкипатчим объект типаисследования добавляя поле parentGroupId которое реально не приходит, что бы оперировать одним типом в результатах поиска и в результатах этого запроса (типы отличаются, приводим к одному)
        it.result.typesOfResearch?.forEach(res => {
          res.parentGroupId = it.result.id;
        });
        return it;
      });
  }

  getGroupsOfResearchByParentId(parentId: number): Promise<BaseResponse<Array<GroupOfResearch_ResponseData>>> {
    return this.http.get(`/api/group-of-research/by-parent-id/${parentId}`).then(it => it.data);
  }

  checkObjectiveState(message: string): Promise<BaseResponse<SendMessage_ResponseData>> {
    return this.http
      .post('/api/objective-state/check', {
        unMarkedText: message,
        languageCode: 'ru',
      })
      .then(it => it.data);
  }

  loadCurrentCheckHistory(): Promise<BaseResponse<Array<CheckJournalHistory_ResponseData>>> {
    return this.http.get('/api/task-journal/current-check-history').then(it => it.data);
  }

  loadTaskById(id: number): Promise<BaseResponse<Task_ResponseData>> {
    return this.http.get(`/api/task/${id}`).then(res => res.data);
  }

  unfinishedTask(): Promise<BaseResponse<TaskProceed_ResponseData>> {
    return this.http.get('/api/task-proceed/unfinished-task').then(res => res.data);
  }

  setTaskRating(grade: any, description: string): Promise<BaseResponse<TaskRating_ResponseData>> {
    return this.http
      .post('/api/feedback/save', {
        grade,
        description,
      })
      .then(it => it.data);
  }

  finishTask(): Promise<BaseResponse<TaskProceed_ResponseData>> {
    return this.http.post('/api/task-proceed/finish-task').then(res => res.data);
  }

  startTask(taskId: number): Promise<BaseResponse<TaskProceed_ResponseData>> {
    return this.http
      .post('/api/task-proceed/start-task', null, {
        headers: {
          sit_task_id: taskId,
        },
      })
      .then(res => res.data);
  }

  startTask1(taskId: number, championship_id?: any): Promise<BaseResponse<TaskProceed_ResponseData>> {
    return this.http
      .post('/api/task-proceed/start-task', null, {
        headers: {
          sit_task_id: taskId,
          sit_championship_id: championship_id
        },
      })
      .then(res => res.data);
  }

  resetPasswordByEmail(email: string): Promise<BaseResponse<any>> {
    return this.http.post(`api/account/reset-password/init?email=${email}`);
  }

  authenticate(request: AuthenticateRequest): Promise<AxiosResponse<JwtToken>> {
    return this.http.post('api/authenticate', request);
  }

  authenticateTypeNew(data: any): Promise<BaseResponse<any>> {
    return this.http.post('api/authorize', data)
  }

  sendMessage(message: String): Promise<BaseResponse<SendMessage_ResponseData>> {
    return this.http
      .post('/api/chat/send-message', {
        unMarkedText: message,
        languageCode: 'ru',
      })
      .then(res => res.data);
  }

  registration(request: RegistrationRequest): Promise<void> {
    return this.http.post('api/register', request);
  }

  getCurrentJournalHistory(): Promise<BaseResponse<Array<JournalHistory_ResponseData>>> {
    return this.http.get('/api/task-journal/current-chat-history').then(it => it.data);
  }

  getChatById(chatId: string): Promise<Array<ChatMessage>> {
    return delay(1000).then(() => []);
  }

  setNewPassword(password: string): Promise<void> {
    return delay(1000).then(() => {
      return;
    });
  }

  async updateUserProfile(name: string, lastName: string, phone: string): Promise<void> {
    await delay(1000);
    return;
  }

  loadIllnessSeverity(): Promise<BaseResponse<Array<any>>> {
    return this.http.get(`/api/illness_severity`).then(it => it.data);
  }

  loadIllnessRisk(): Promise<BaseResponse<Array<any>>> {
    return this.http.get(`/api/illness_risk`).then(it => it.data);
  }

  loadStudentSpecialities(): Promise<BaseResponse<Array<StudentSpeciality_ResponseData>>> {
    return this.http.get(`/api/student_speciality`).then(it => it.data);
  }

  loadMedicine(): Promise<BaseResponse<Array<Medicine_ResponseData>>> {
    return this.http.get('/api/medicine/all').then(it => it.data);
  }

  loadMedicineByUniversityLevel(universityLevel: string, difficultId: string): Promise<BaseResponse<Array<Medicine_ResponseData>>> {
    return this.http.get(`/api/medicine/${universityLevel}/${difficultId}`).then(it => it.data);
  }

  loadTypesOfDifficultLevels(): Promise<BaseResponse<Array<TypeOfDifficultLevel_ResponseData>>> {
    return this.http.get('/api/type-of-difficult-level/all').then(res => res.data);
  }

  loadTypesOfDifficultLevelsByUniversityLevel(universityLevel: string): Promise<BaseResponse<Array<TypeOfDifficultLevel_ResponseData>>> {
    return this.http.get(`/api/type-of-difficult-level/${universityLevel}`).then(res => res.data);
  }

  loadTasks(): Promise<BaseResponse<Array<Task_ResponseData>>> {
    return this.http.get('/api/task').then(res => res.data);
  }

  loadIllnessStage(): Promise<BaseResponse<Array<any>>> {
    return this.http.get(`/api/illness_stage`).then(it => it.data);
  }

  loadIllnessPhase(): Promise<BaseResponse<Array<any>>> {
    return this.http.get(`/api/illness_phase`).then(it => it.data);
  }

  getTest(body: bodyGetTest): Promise<BaseResponse<Get_Test>> {
    return this.http.post(`/api/test/getTest`, body).then(res => res.data);
  }
  setTestAnswers(body: set_Test_Answers): Promise<BaseResponse<Array<set_Test_Answers>>> {
    return this.http.post(`/api/test/setTestAnswers`, body).then(res => res.data);
  }

  getAnamnesis(): Promise<BaseResponse<Array<type_Anamnesis>>> {
    return this.http.get(`/api/anamnesisType`).then(res => res.data);
  }

  getConnectionWithIllness(): Promise<BaseResponse<Array<get_Connection_With_Illness>>> {
    return this.http.get(`/api/connectionWithIllness`).then(res => res.data);
  }

  getDefaultMeanList(id: number | undefined): Promise<BaseResponse<Array<get_Default_Mean_List>>> {
    return this.http.get(`/api/constructor/communication/get/${id}`).then(res => res.data);
  }

  createMean(body: create_Mean): Promise<BaseResponse<create_Mean>> {
    return this.http.post(`/api/constructor/communication/create`, body).then(res => res.data);
  }
  getAnamnesisType(id: number): Promise<BaseResponse<Array<type_Anamnesis>>> {
    return this.http.get(`api/constructor/communication/get/anamniesis_by_type?type=${id}`).then(res => res.data);
  }
  updateMeaning(body: update_Meaning): Promise<BaseResponse<update_Meaning>> {
    return this.http.put(`api/constructor/communication/update`, body).then(res => res.data);
  }
  deleteMeaning(id: number | undefined): Promise<BaseResponse<delete_Meaning>> {
    return this.http.delete(`api/constructor/communication/delete/${id}`).then(res => res.data);
  }
  getPatientConscious(): Promise<BaseResponse<any>> {
    return this.http.get(`/api/patient_conscious`).then(res => res.data);
  }
  getPatientState(): Promise<BaseResponse<Array<any>>> {
    return this.http.get(`/api/patient_state`).then(res => res.data);
  }
  createObjectiveStatus(body: any): Promise<BaseResponse<any>> {
    return this.http.post(`api/constructor/objective_status/create`, body).then(res => res.data);
  }
  getObjectiveStatus(id: number): Promise<BaseResponse<Array<get_Objective_Status>>> {
    return this.http.get(`/api/constructor/objective_status/get/${id}`).then(res => res.data);
  }
  getCurrentObjectiveStatus(id: number): Promise<BaseResponse<get_Current_Objective_Status>> {
    return this.http.get(`/api/constructor/objectivestatus/getItem/${id}`).then(res => res.data);
  }
  updateCurrentObjectiveStatus(body: update_Current_Objective_status): Promise<BaseResponse<update_Current_Objective_status>> {
    return this.http.put(`api/constructor/objective_status/update`, body).then(res => res.data);
  }
  getExcrementPhoto(): Promise<BaseResponse<Array<get_Excrement_Photo>>> {
    return this.http.get(`/api/excrementphoto`).then(res => res.data);
  }
  getExcrementComfort(): Promise<BaseResponse<Array<get_Excrement_Photo>>> {
    return this.http.get(`/api/excrementcomfort`).then(res => res.data);
  }
  getExcrementColor(): Promise<BaseResponse<Array<get_Excrement_Photo>>> {
    return this.http.get(`/api/excrementcolor`).then(res => res.data);
  }
  getExcrementSmell(): Promise<BaseResponse<Array<get_Excrement_Photo>>> {
    return this.http.get(`/api/excrementsmell`).then(res => res.data);
  }
  getUrinePressure(): Promise<BaseResponse<Array<get_Urine_Status>>> {
    return this.http.get(`/api/urinepressure`).then(res => res.data);
  }
  getUrineColor(): Promise<BaseResponse<Array<get_Urine_Status>>> {
    return this.http.get(`/api/urinecolor`).then(res => res.data);
  }
  getUrineSmell(): Promise<BaseResponse<Array<get_Urine_Status>>> {
    return this.http.get(`/api/urinesmell`).then(res => res.data);
  }
  getUrineImpurity(): Promise<BaseResponse<Array<get_Urine_Status>>> {
    return this.http.get(`/api/urineimpurity`).then(res => res.data);
  }
  getUrineTransparency(): Promise<BaseResponse<Array<get_Urine_Status>>> {
    return this.http.get(`/api/urinetransparency`).then(res => res.data);
  }
  getUrineFoam(): Promise<BaseResponse<Array<get_Urine_Status>>> {
    return this.http.get(`/api/urinefoam`).then(res => res.data);
  }
  getUrineComfort(): Promise<BaseResponse<Array<get_Urine_Status>>> {
    return this.http.get(`/api/urinecomfort`).then(res => res.data);
  }
  getUrineAdditionalFenomen(): Promise<BaseResponse<Array<get_Urine_Status>>> {
    return this.http.get(`/api/urineadditionalfenomen`).then(res => res.data);
  }
  getAllAnamnesisList(): Promise<BaseResponse<Array<get_Connection_With_Illness>>> {
    return this.http.get(`api/constructor/communication/get/anamniesis`).then(res => res.data);
  }
  createResearchInstrument(body: createResearchInstrument): Promise<BaseResponse<createResearchInstrument>> {
    return this.http.post(`api/constructor/research/create?type=true`, body).then(res => res.data);
  }
  updateResearchInstrument(body: createResearchInstrument): Promise<BaseResponse<createResearchInstrument>> {
    return this.http.put(`api/constructor/research/update?instrumental=true`, body).then(res => res.data);
  }
  createResearchLab(body: createResearchInstrument): Promise<BaseResponse<createResearchInstrument>> {
    return this.http.post(`api/constructor/research/create?type=false`, body).then(res => res.data);
  }
  updateResearchLab(body: createResearchInstrument): Promise<BaseResponse<createResearchInstrument>> {
    return this.http.put(`api/constructor/research/update?instrumental=false`, body).then(res => res.data);
  }
  getSituationList(): Promise<BaseResponse<get_Inspection_Side>> {
    return this.http.get(`api/situational_reception`).then(res => res.data);
  }
  getRandomTask(): Promise<BaseResponse<Task_ResponseData>> {
    return this.http.get(`api/task/difficult_task?typeOfDifficultLevelId=3&universityLevelId=4`).then(res => res.data);
  }

  getCompetenceAll(): Promise<BaseResponse<CompetenceType>> {
    return this.http.get(`api/competence/get_all`).then(res => res.data);
  }
  getCurrentCompetence(id: number): Promise<BaseResponse<CompetenceType>> {
    return this.http.get(`api/competence/get/${id}`).then(res => res.data);
  }
  deleteCompetence(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`api/competence/delete/${id}`).then(res => res.data);
  }
  updateCompetence(body: CompetenceType): Promise<BaseResponse<CompetenceType>> {
    return this.http.put(`api/competence/update`, body).then(res => res.data);
  }
  createCompetence(body: string): Promise<BaseResponse<CompetenceType>> {
    return this.http.post(`api/competence/create`, body).then(res => res.data);
  }

  getAllSpecialties(): Promise<BaseResponse<SpecialtiesInterface>> {
    return this.http.get(`api/doctors_info/get_all`).then(res => res.data);
  }
  getCurrentSpecialties(id: number): Promise<BaseResponse<SpecialtiesInterface>> {
    return this.http.get(`api/doctors_info/get/${id}`).then(res => res.data);
  }
  deleteCurrentSpecialties(id: number): Promise<BaseResponse<SpecialtiesInterface>> {
    return this.http.delete(`api/doctors_info/delete/${id}`).then(res => res.data);
  }
  createCurrentSpecialties(body: SpecialtiesInterface): Promise<BaseResponse<SpecialtiesInterface>> {
    return this.http.post(`api/doctors_info/create`, body).then(res => res.data);
  }
  updateCurrentSpecialties(body: SpecialtiesInterface): Promise<BaseResponse<SpecialtiesInterface>> {
    return this.http.put(`api/doctors_info/update`, body).then(res => res.data);
  }

  getTypeOfMedicalDictionaryAll(): Promise<BaseResponse<get_Medical_Dictionary_All>> {
    return this.http.get(`api/type_of_medical_dictionary/all?page=0&size=20000`).then(res => res.data);
  }

  getAllMedicalDictionaryList(): Promise<BaseResponse<get_Medical_Dictionary_All>> {
    return this.http.get(`api/medical-dictionary/all?page=0&size=20000`).then(res => res.data);
  }
  createMedicalDictionary(body: any): Promise<BaseResponse<any>> {
    return this.http.post(`/api/medical-dictionary/create`, body).then(res => res.data);
  }
  deleteMedicalDictionary(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`/api/medical-dictionary/delete/${id}`).then(res => res.data);
  }
  updateMedicalDictionary(body: any): Promise<BaseResponse<any>> {
    return this.http.put(`/api/medical-dictionary/update`, body).then(res => res.data);
  }

  getAllTypeMedicalDictionaryList(): Promise<BaseResponse<get_Medical_Dictionary_All>> {
    return this.http.get(`api/type_of_medical_dictionary/all?page=0&size=30`).then(res => res.data);
  }
  createTypeMedicalDictionary(body: any): Promise<BaseResponse<any>> {
    return this.http.post(`/api/type_of_medical_dictionary/create`, body).then(res => res.data);
  }
  deleteTypeMedicalDictionary(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`/api/type_of_medical_dictionary/delete/${id}`).then(res => res.data);
  }
  updateTypeMedicalDictionary(body: any): Promise<BaseResponse<any>> {
    return this.http.put(`/api/type_of_medical_dictionary/update`, body).then(res => res.data);
  }
  getModesAll(): Promise<BaseResponse<any>> {
    return this.http.get(`api/mode/get_all`).then(res => res.data);
  }
  createMode(body: any): Promise<BaseResponse<modesReferenceInterface>> {
    return this.http.post(`/api/mode/create`, body).then(res => res.data);
  }
  updateMode(body: any): Promise<BaseResponse<modesReferenceInterface>> {
    return this.http.put(`/api/mode/update`, body).then(res => res.data);
  }
  deleteMode(id: number): Promise<BaseResponse<modesReferenceInterface>> {
    return this.http.delete(`/api/mode/delete/${id}`).then(res => res.data);
  }

  getNursingCareAll(): Promise<BaseResponse<nursingCareInterface>> {
    return this.http.get(`/api/sister_care/get_all`).then(res => res.data);
  }
  updateNursingCare(body: nursingCareInterface): Promise<BaseResponse<nursingCareInterface>> {
    return this.http.put(`/api/sister_care/update`, body).then(res => res.data);
  }
  createNursingCare(body: nursingCareInterface): Promise<BaseResponse<nursingCareInterface>> {
    return this.http.post(`/api/sister_care/create`, body).then(res => res.data);
  }
  deleteNursingCare(id: number): Promise<BaseResponse<number>> {
    return this.http.delete(`/api/sister_care/delete/${id}`).then(res => res.data);
  }
  deleteNursingByTaskCare(id: number): Promise<BaseResponse<number>> {
    return this.http.delete(`api/constructor/standart_alternative_medication/delete/${id}`).then(res => res.data);
  }
  createNursingByTaskCare(body: any): Promise<BaseResponse<any>> {
    return this.http.post(`/api/constructor/standart_alternative_medication/create/sister_care`, body).then(res => res.data);
  }





  createNursingCareTreatment(body: nursingCareInterface): Promise<BaseResponse<nursingCareInterface>> {
    return this.http.post(`/api/alterativemedication/create/sister_care`, body).then(res => res.data);
  }
  getNursingCareAlternativeList(): Promise<BaseResponse<any[]>> {
    return this.http.get(`/api/alterativemedication/gettaskid/sister_care`).then(res => res.data)
  }
  getNursingCareResult(): Promise<BaseResponse<nursingCareListInterface[]>> {
    return this.http.get(`/api/standartalternativemedication/result/sister_care`).then(res => res.data)
  }
  updateConstructorNursingCare(body: any): Promise<BaseResponse<any>> {
    return this.http.put(`/api/constructor/standart_alternative_medication/update/sister_care`, body).then(res => res.data)
  }
  getSurgeonTreatment(): Promise<BaseResponse<nursingCareInterface>> {
    return this.http.get(`/api/surgeon_treatment/get_all`).then(res => res.data);
  }
  updateSurgeonTreatment(body: nursingCareInterface): Promise<BaseResponse<nursingCareInterface>> {
    return this.http.put(`/api/surgeon_treatment/update`, body).then(res => res.data);
  }
  createSurgeonTreatment(body: nursingCareInterface): Promise<BaseResponse<nursingCareInterface>> {
    return this.http.post(`/api/surgeon_treatment/create`, body).then(res => res.data);
  }
  deleteSurgeonTreatment(id: number): Promise<BaseResponse<number>> {
    return this.http.delete(`/api/surgeon_treatment/delete/${id}`).then(res => res.data);
  }
  updateConstructorSurgeonTreatment(body: surgicalTreatmentConstructorInterface): Promise<BaseResponse<surgicalTreatmentConstructorInterface>> {
    return this.http.put(`/api/constructor/standart_alternative_medication/update/surgeon_treatment`, body).then(res => res.data)
  }
  createConstructorSurgeonTreatment(body: surgicalTreatmentConstructorInterface): Promise<BaseResponse<surgicalTreatmentConstructorInterface>> {
    return this.http.post(`/api/constructor/standart_alternative_medication/create/surgeon_treatment`, body).then(res => res.data)
  }
  deleteConstructorSurgeonTreatment(id: number): Promise<BaseResponse<number>> {
    return this.http.delete(`api/constructor/standart_alternative_medication/delete/${id}`).then(res => res.data);
  }

  getModulesAll(): Promise<BaseResponse<Medicine_ResponseData>> {
    return this.http.get(`/api/medicine/all`).then(res => res.data);
  }
  updateModules(body: Medicine_ResponseData): Promise<BaseResponse<Medicine_ResponseData>> {
    return this.http.put(`/api/medicine/update`, body).then(res => res.data);
  }
  createModules(body: Medicine_ResponseData): Promise<BaseResponse<Medicine_ResponseData>> {
    return this.http.post(`/api/medicine/create`, body).then(res => res.data);
  }
  deleteModules(id: number): Promise<BaseResponse<number>> {
    return this.http.delete(`/api/medicine/delete/${id}`).then(res => res.data);
  }

  getThemesAll(): Promise<BaseResponse<themeInterface[]>> {
    return this.http.get(`/api/themes/all`).then(res => res.data);
  }
  getCurrentTheme(id: number): Promise<BaseResponse<themeInterface>> {
    return this.http.get(`/api/themes/get/${id}`).then(res => res.data);
  }
  deleteTheme(id: number): Promise<BaseResponse<number>> {
    return this.http.delete(`/api/themes/delete/${id}`).then(res => res.data);
  }
  createTheme(theme: any): Promise<BaseResponse<themeInterface>> {
    return this.http.post(`/api/themes/create`, theme).then(res => res.data);
  }
  updateTheme(theme: any): Promise<BaseResponse<themeInterface>> {
    return this.http.put(`/api/themes/update`, theme).then(res => res.data);
  }

  getNoneMedicalTreatmentAll(): Promise<BaseResponse<modesReferenceInterface>> {
    return this.http.get(`/api/non_medical_treatment/get_all`).then(res => res.data);
  }
  createNoneMedicalTreatment(body: modesReferenceInterface): Promise<BaseResponse<modesReferenceInterface>> {
    return this.http.post(`/api/non_medical_treatment/create`, body).then(res => res.data);
  }
  updateNoneMedicalTreatment(body: modesReferenceInterface): Promise<BaseResponse<modesReferenceInterface>> {
    return this.http.put(`/api/non_medical_treatment/update`, body).then(res => res.data);
  }
  deleteNoneMedicalTreatment(id: number): Promise<BaseResponse<number>> {
    return this.http.delete(`/api/non_medical_treatment/delete/${id}`).then(res => res.data);
  }

  getNoneMedicalTreatmentTypeAll(): Promise<BaseResponse<modesReferenceInterface>> {
    return this.http.get(`/api/non_medical_treatment_type/get_all`).then(res => res.data);
  }
  createNoneMedicalTreatmentType(body: modesReferenceInterface): Promise<BaseResponse<modesReferenceInterface>> {
    return this.http.post(`/api/non_medical_treatment_type/create`, body).then(res => res.data);
  }
  updateNoneMedicalTreatmentType(body: modesReferenceInterface): Promise<BaseResponse<modesReferenceInterface>> {
    return this.http.put(`/api/non_medical_treatment_type/update`, body).then(res => res.data);
  }
  deleteNoneMedicalTreatmentType(id: number): Promise<BaseResponse<number>> {
    return this.http.delete(`/api/non_medical_treatment_type/delete/${id}`).then(res => res.data);
  }

  getMatrixCompetenceAll(): Promise<BaseResponse<matrixCompetenceInterface>> {
    return this.http.get(`/api/doctors_info_competence/get`).then(res => res.data);
  }
  createMatrixCompetence(body: matrixCompetenceInterface): Promise<BaseResponse<matrixCompetenceInterface>> {
    return this.http.post(`/api/doctors_info_competence/create`, body).then(res => res.data);
  }

  depersonalizedRatingData(body: depersonalizedRatingInterface): Promise<BaseResponse<depersonalizedRatingInterface>> {
    return this.http.post(`/api/task/get_raiting_data`, body).then(res => res.data)
  }

  getAllDoctorsType(): Promise<BaseResponse<SpecialtiesInterface[]>> {
    return this.http.get(`/api/doctors_info/get_all`).then(res => res.data)
  }

  getStressQuestion(phrasesId: number): Promise<BaseResponse<any>> {
    return this.http.get(`/api/trigger_question/get_question/${phrasesId}`).then(res => res.data)
  }
  getNumberStressQuestion(phrasesId: number): Promise<BaseResponse<any>> {
    return this.http.get(`/api/trigger_question/get_number_of_questions/${phrasesId}`).then(res => res.data)
  }

  loadTasksBy(universityLevelId: number, medicineId: number, typeOfDifficultLevelId: number): Promise<BaseResponse<Array<Task_ResponseData>>> {
    return this.http
      .get('/api/task/list', {
        params: {
          universityLevelId: universityLevelId,
          medicineId: medicineId,
          typeOfDifficultLevelId: typeOfDifficultLevelId,
        },
      })
      .then(res => res.data);
  }
  getResearchResults(researches: Array<ResearchItem_RequestData>): Promise<BaseResponse<Array<ResearchResults_ResponseData>>> {
    return this.http.post('/api/research/get', researches).then(res => res.data);
  }
  getTreatmentSchemeById(id: number): Promise<BaseResponse<treatmentSchemeInterface[]>> {
    return this.http.get(`api/treatment_scheme/get_treatment_scheme/${id}`).then(res => res.data)
  }
  createTreatmentScheme(treatment: any): Promise<BaseResponse<any>> {
    return this.http.post(`api/treatment_scheme/task_treatment_scheme`, treatment).then(res => res.data)
  }
  deleteTreatmentScheme(taskId: number, schemeId: number): Promise<BaseResponse<number>> {
    return this.http.delete(`api/treatment_scheme/delete_treatment_scheme/${schemeId}/${taskId}`).then(res => res.data)
  }
  updateTreatmentScheme(treatment: treatmentSchemeInterface): Promise<BaseResponse<treatmentSchemeInterface>> {
    return this.http.put(`api/treatment_scheme/update_treatment_scheme`, treatment).then(res => res.data)
  }
  getAllTreatmentScheme(): Promise<BaseResponse<treatmentSchemeInterface[]>> {
    return this.http.get(`api/treatment/getTreatmentScheme`).then(res => res.data)
  }

  getAllGroupOfResearch(): Promise<BaseResponse<groupOfResearchInterface[]>> {
    return this.http.get(`/api/group-of-research`).then(res => res.data)
  }

  getCurrentGroupOfResearch(id: number): Promise<BaseResponse<groupOfResearchInterface[]>> {
    return this.http.get(`api/group-of-research/get_group/${id}`).then(res => res.data)
  }
  deleteCurrentGroupOfResearch(id: number): Promise<BaseResponse<number>> {
    return this.http.delete(`api/group-type-research/delete/${id}`).then(res => res.data)
  }

  createResearchElement(body: groupOfResearchInterface): Promise<BaseResponse<groupOfResearchInterface>> {
    return this.http.post(`api/group-type-research/create`, body).then(res => res.data)
  }
  createResearchGroup(body: any): Promise<BaseResponse<any>> {
    return this.http.post(`api/group-of-research/create`, body).then(res => res.data)
  }
  updateResearchGroup(body: groupOfResearchInterface): Promise<BaseResponse<groupOfResearchInterface>> {
    return this.http.put(`api/group-of-research/update`, body).then(res => res.data)
  }
  deleteResearchGroup(id: number): Promise<BaseResponse<number>> {
    return this.http.delete(`api/group-of-research/delete/${id}`).then(res => res.data)
  }
  teacherReviewGetAll(body: any): Promise<BaseResponse<any>> {
    return this.http.post(`api/teacher_review/get`, body).then(res => res.data)
  }
  teacherReviewCreate(body: reviewCreate): Promise<BaseResponse<reviewCreate>> {
    return this.http.post(`api/teacher_review/create`, body).then(res => res.data)
  }
  deleteReviewTeacher(id: number): Promise<BaseResponse<number>> {
    return this.http.delete(`api/teacher_review/delete/${id}`).then(res => res.data)
  }

  getAllPatients(): Promise<BaseResponse<allPatient[]>> {
    return this.http.get(`api/patient/get`).then(res => res.data)
  }
  createPatient(body: allPatient): Promise<BaseResponse<allPatient>> {
    return this.http.post(`api/patient/create`, body).then(res => res.data)
  }
  updatePatient(body: allPatient): Promise<BaseResponse<allPatient>> {
    return this.http.put(`api/patient/update`, body).then(res => res.data)
  }
  getAllModel(): Promise<BaseResponse<models[]>> {
    return this.http.get(`api/patientmodel`).then(res => res.data)
  }

  createModel(body: models): Promise<BaseResponse<models>> {
    return this.http.post(`api/patientmodel/create`, body).then(res => res.data)
  }

  updateModel(body: models): Promise<BaseResponse<models>> {
    return this.http.put(`api/patientmodel/update`, body).then(res => res.data)
  }
  deletePatientModel(id: number): Promise<BaseResponse<number>> {
    return this.http.delete(`api/patientmodel/delete/${id}`).then(res => res.data)
  }

  getPatientModelParameters(id: number): Promise<BaseResponse<any>> {
    return this.http.get(`api/patient_model_parameters/get_model_parameters/${id}`).then(res => res.data)
  }
  createPatientModelParameters(body: any): Promise<BaseResponse<any>> {
    return this.http.post(`api/patient_model_parameters/create`, body).then(res => res.data)
  }
  deletePatientModelParameters(id: number): Promise<BaseResponse<number>> {
    return this.http.delete(`api/patient_model_parameters/delete/${id}`).then(res => res.data)
  }
  finishTaskConstructor(id: number, isDisabled?: boolean): Promise<BaseResponse<any>> {
    let url = `/api/constructor/finish?taskId=${id}`;
    if (isDisabled !== undefined) {
      url += `&disabled=${isDisabled}`;
    }
    return this.http.get(url).then(res => res.data);
  }

  getNoGroupType(): Promise<BaseResponse<any>> {
    return this.http.get(`api/group-of-research/no_group_type`).then(res => res.data)
  }

  getSpecialistsAdvice(): Promise<BaseResponse<any>> {
    return this.http.get(`api/anamnesis_specialist_advice/all`).then(res => res.data)
  }

  getSpecialistAdviceByTask(id: number): Promise<BaseResponse<any>> {
    return this.http.get(`api/constructor/specialist_advice/get_by_task/${id}`).then(res => res.data)
  }

  createSpecialistAdvice(body: createdSpecialist): Promise<BaseResponse<createdSpecialist>> {
    return this.http.post(`api/constructor/specialist_advice/create`, body).then(res => res.data)
  }

  deleteSpecialistAdvice(id: number): Promise<BaseResponse<number>> {
    return this.http.delete(`api/constructor/specialist_advice/delete/${id}`).then(res => res.data)
  }

  updateSpecialistAdvice(body: createdSpecialist): Promise<BaseResponse<createdSpecialist>> {
    return this.http.put(`api/constructor/specialist_advice/update`, body).then(res => res.data)
  }

  getAllQuestions(): Promise<BaseResponse<questionsInterface[]>> {
    return this.http.get(`/api/test_quest/get_all_with_roles`).then(res => res.data)
  }

  createQuestion(body: questionsInterface): Promise<BaseResponse<questionsInterface>> {
    return this.http.post(`/api/test_quest/test_quest`, body).then(res => res.data)
  }

  updateQuestion(body: questionsInterface): Promise<BaseResponse<questionsInterface>> {
    return this.http.put(`/api/test_quest/update`, body).then(res => res.data)
  }

  deleteQuestion(id: string): Promise<BaseResponse<string>> {
    return this.http.delete(`/api/test_quest/delete/${id}`).then(res => res.data)
  }

  getCurrentAnswers(id: string): Promise<BaseResponse<currentAnswers[]>> {
    return this.http.get(`/api/test_answers/get_by_quest_uid/${id}`).then(res => res.data)
  }

  createAnswer(body: currentAnswers): Promise<BaseResponse<currentAnswers>> {
    return this.http.post(`/api/test_answers/create`, body).then(res => res.data)
  }
  deleteAnswer(id: string): Promise<BaseResponse<string>> {
    return this.http.delete(`/api/test_answers/delete/${id}`).then(res => res.data)
  }
  updateAnswer(body: currentAnswers): Promise<BaseResponse<currentAnswers>> {
    return this.http.put(`/api/test_answers/update`, body).then(res => res.data)
  }


  getQuestionsOfTask(id: number): Promise<BaseResponse<taskQuestion[]>> {
    return this.http.get(`/api/task_test/get_task_test/${id}`).then(res => res.data)
  }

  getQuestionsOfTaskAll(id: number): Promise<BaseResponse<taskQuestion[]>> {
    return this.http.post(`/api/task_test/set_all_test_quest_for_theme/${id}`).then(res => res.data)
  }

  setQuestionForTask(question: taskQuestion): Promise<BaseResponse<taskQuestion>> {
    return this.http.post(`/api/task_test/set_task_test`, question).then(res => res.data)
  }

  deleteQuestionTask(id: string): Promise<BaseResponse<string>> {
    return this.http.delete(`/api/task_test/delete/${id}`).then(res => res.data)
  }

  getAdminUser(login: string): Promise<BaseResponse<any>> {
    return this.http.get(`/api/admin_user/${login}`)
  }
  changeStressCheck(userId: number): Promise<BaseResponse<any>> {
    return this.http.post(`api/stress/activate/${userId}`).then(res => res.data)
  }

  changeCognitiveCheck(userId: number, active: boolean): Promise<BaseResponse<any>> {
    return this.http.post(`api/cognitive/activate/${userId}/${active}`, {}).then(res => res.data)
  }
  getTaskDetail(id: number): Promise<BaseResponse<any>> {
    return this.http.get(`api/constructor/task/get/${id}`).then(res => res.data)
  }

  setReviewLog(body: reviewLogAnswers): Promise<BaseResponse<any>> {
    return this.http.post(`api/review_log/create`, body).then(res => res.data)
  }
  getPilotingTask(body: pilotingTaskInterface): Promise<BaseResponse<pilotingTaskInterface>> {
    return this.http.post(`api/piloting_task/get`, body).then(res => res.data)

  }

  getHint(): Promise<BaseResponse<any>> {
    return this.http.get(`/api/hint`).then(res => res.data)
  }

  getPatientModelVoiceResource(): Promise<BaseResponse<any>> {
    return this.http.get(`/api/patient_model_voice`).then(res => res.data)
  }
  getPatientModelVoiceResourceById(id: number): Promise<BaseResponse<any>> {
    return this.http.get(`/api/patient_model_voice/get_by_patient_model/${id}`).then(res => res.data)
  }
  updatePatientModelVoiceResource(body: PatientModelVoice): Promise<BaseResponse<any>> {
    return this.http.put(`/api/patient_model_voice/update`, body).then(res => res.data)
  }
  deletePatientModelVoiceResource(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`/api/patient_model_voice/delete/${id}`).then(res => res.data)
  }
  createPatientModelVoice(body: PatientModelVoice): Promise<BaseResponse<any>> {
    return this.http.post(`/api/patient_model_voice/create`, body).then(res => res.data)
  }

  generateEmailCode(email: string): Promise<BaseResponse<any>> {
    return this.http.post(`/api/generate_email_code?email=${email}`).then(res => res.data)
  }

  sendTestResultOnEmail(body: sendTestResultOnEmail): Promise<BaseResponse<any>> {
    return this.http.post(`/api/send_email`, body).then(res => res.data)
  }
  getListOfStages(): Promise<BaseResponse<any>> {
    return this.http.get(`/api/testing-stage`).then(res => res.data)
  }

  getTestingStageHistory(): Promise<BaseResponse<any>> {
    return this.http.get(`/api/testing_stage_history/get`).then(res => res.data)
  }

  setTestingStageHistory(id: number): Promise<BaseResponse<any>> {
    return this.http.post(`/api/testing_stage_history/set/${id}`).then(res => res.data)
  }

  getTypeOfDifficultLevel(): Promise<BaseResponse<any>> {
    return this.http.get(`/api/type-of-difficult-level/all`).then(res => res.data)
  }
  copyTaskOnConstructor(id: number): Promise<BaseResponse<any>> {
    return this.http.post(`/api/constructor/task/copy_task/${id}`).then(res => res.data)
  }

  getDoctorsInfoFilteringTests(): Promise<BaseResponse<any>> {
    return this.http.get(`/api/doctors_info/filtering_tests`).then(res => res.data)
  }

  getLegendOption(id: number): Promise<BaseResponse<any>> {
    return this.http.get(`/api/legend_options/get_by_task/${id}`).then(res => res.data)
  }

  createLegendOption(body: LegendOption): Promise<BaseResponse<any>> {
    return this.http.post(`/api/legend_options/create`, body).then(res => res.data)
  }

  updateLegendOption(body: LegendOption): Promise<BaseResponse<any>> {
    return this.http.put(`/api/legend_options/update`, body).then(res => res.data)
  }

  deleteLegendOption(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`/api/legend_options/delete/${id}`).then(res => res.data)
  }

  getAllRecipe(): Promise<BaseResponse<any>> {
    return this.http.get(`api/recipe`).then(res => res.data)
  }

  getRecipeDetailsTypes(): Promise<BaseResponse<any>> {
    return this.http.get(`api/recipe_details_types`).then(res => res.data)
  }

  createRecipeTaskDetail(body: RecipeTaskDetail): Promise<BaseResponse<any>> {
    return this.http.post(`api/recipe_taskdetail/create`, body).then(res => res.data)
  }

  deleteRecipeTaskDetail(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`api/recipe_taskdetail/delete/${id}`).then(res => res.data)
  }

  getRecipeTaskDetailByProceedTask(): Promise<BaseResponse<any>> {
    return this.http.get(`api/recipe_taskdetail/get_by_proceed_task`).then(res => res.data)
  }

  getRecipeTaskDetailByTask(id: number): Promise<BaseResponse<any>> {
    return this.http.get(`api/recipe_taskdetail/get_by_task/${id}`).then(res => res.data)
  }

  updateRecipeTaskDetail(body: RecipeTaskDetail): Promise<BaseResponse<any>> {
    return this.http.put(`api/recipe_taskdetail/update`, body).then(res => res.data)
  }

  createRecipeTask(body: RecipeTask): Promise<BaseResponse<any>> {
    return this.http.post(`api/recipe_task/create`, body).then(res => res.data)
  }
  
  deleteRecipeTask(id: number): Promise<BaseResponse<any>> {
    return this.http.delete(`api/recipe_task/delete/${id}`).then(res => res.data)
  }
  getRecipeTask(): Promise<BaseResponse<any>> {
    return this.http.get(`api/recipe_task/get_by_proceed_task`).then(res => res.data)
  }

  getRecipeTaskById(id: number): Promise<BaseResponse<any>> {
    return this.http.get(`api/recipe_task/get_by_task/${id}`).then(res => res.data)
  }

  getRecipeUserTaskProceed(): Promise<BaseResponse<any>> {
    return this.http.get(`api/recipe_usertaskproceed/get`).then(res => res.data)
  }

  createRecipeUserTaskProceed(body: {}): Promise<BaseResponse<any>> {
    return this.http.post(`api/recipe_usertaskproceed/save`, body).then(res => res.data)
  }
  getRecipeUserTaskProceedActive(): Promise<BaseResponse<any>> {
    return this.http.get(`api/recipe_usertaskproceed_active/check`).then(res => res.data)
  }

  createRecipeUserTaskProceedActive(body: {}): Promise<BaseResponse<any>> {
    return this.http.post(`api/recipe_usertaskproceed_active/save`, body).then(res => res.data)
  }

  static createInstance() {
    const axiosInstance = axios.create({
      baseURL: buildConfig.buildType === BuildType.production ? '/api' : '/',
    });

    axiosInstance.interceptors.request.use((value: AxiosRequestConfig) => {
      const jwtToken = store.getState().auth.jwtToken;
      value.headers['Authorization'] = jwtToken ? `Bearer ${jwtToken}` : undefined;
      value.headers['session_id'] = 'fc97ee31-2970-4b7f-b5f4-60644308819b';
      // value.headers['sit_task_id'] = '1';

      // todo use redux store for save this data persistent
      if (value.data !== null && typeof value.data === 'object') {
        value.data.apiKey = 'ygyhghkjjkghxrzawedfh7654fhukjkki';
        value.data.ip = '192.168.1.222';
        // value.data.userId = 'c2d9e41e-4935-4678-9f23-e56a8a626573';
      }
      return value;
    });

    const createError = (error: any): AxiosError => {
      if (error.response) {
        const response = error.response;
        const message = response.data?.description?.length > 0
          ? response.data.description.map((it: any) => it.message).join('\n')
          : `Ошибка, попробуйте позже. (Статус код: ${response.status})`;

        const axiosError: AxiosError = new Error(message) as AxiosError;
        axiosError.response = response;
        return axiosError;
      } else {
        return new Error('Произошла ошибка. Пожалуйста, попробуйте позже.') as AxiosError;
      }
    };



    axiosInstance.interceptors.response.use(
      (res: AxiosResponse) => {
        if (res.data.isSuccessefull === false) {
          return Promise.reject(createError(res));
        }
        return res;
      },
      err => {
        if (err.response.status === 401) {
          store.dispatch({ type: AuthAction.LOGOUT, data: null });
        }
        return Promise.reject(createError(err));
      }
    );

    return new ChatApiImpl(axiosInstance);
  }
}
