import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";

import './style.css';
import { ReactComponent as Doctor } from '../../../../../assets/img/pharmacy/DOCTOR.svg';
import { ReactComponent as MedicalCommission } from '../../../../../assets/img/pharmacy/medicalCommission.svg';
import { ReactComponent as Recipe } from '../../../../../assets/img/pharmacy/recipe.svg';
import { ReactComponent as Stamp } from '../../../../../assets/img/pharmacy/stamp.svg';

interface Form148BackProps {
  onInputChange?: (id: number, value: string) => void;
  initialValues: InitialValuesType;
  onCheckboxStateChange?: (checkboxStates: {
    inputs: { [key: number]: boolean };
    svgs: { [key: number]: boolean };
  }) => void;
  isShow?: boolean;
  isReadOnly?: boolean;
  isShowDeleteButton?: boolean;
  formDroppedItems?: SvgType[];
  handleDragStart?: (index: number, type: string) => void;
  handleDragOver?: () => void;
  handleDrop?: (event: React.DragEvent<HTMLDivElement>) => void;
  onSvgDelete?: (index: number, id: number) => void;
  isConstructor?: boolean;
  isChatPage?: boolean;
}

interface InitialValuesType {
  inputs?: {
    [key: number]: {
      value: string | undefined;
      right?: boolean;
      [key: string]: any;
    };
  };
  svgs?: Array<SvgType>;
}

interface SvgType {
  id: number;
  type: string;
  x: number;
  y: number;
  isWrongAnswer: boolean;
}

const Form148Back = forwardRef(({
  initialValues,
  onInputChange,
  formDroppedItems = [],
  handleDragStart,
  handleDragOver,
  handleDrop,
  onSvgDelete,
  isShow,
  isReadOnly,
  onCheckboxStateChange,
  isConstructor,
  isChatPage
}: Form148BackProps, ref) => {
  const [inputCheckboxStates, setInputCheckboxStates] = useState<{ [key: number]: boolean }>({});
  const [svgCheckboxStates, setSvgCheckboxStates] = useState<{ [key: number]: boolean }>({});
  const [values, setValues] = useState(() => ({
    inputs: initialValues?.inputs || {},
  }));

  useEffect(() => {
    setValues((prevValues) => ({
      inputs: {
        ...prevValues.inputs,
        ...initialValues.inputs,
      },
    }));
  }, [initialValues.inputs]);

  useEffect(() => {
    if (initialValues?.inputs) {
      const initialCheckboxStates = Object.fromEntries(
        Object.entries(initialValues.inputs).map(([id, input]) => [
          Number(id),
          input.right || false,
        ])
      );
      setInputCheckboxStates(initialCheckboxStates);
    }
  }, [initialValues?.inputs]);

  useEffect(() => {
    if (initialValues?.svgs) {
      const initialSvgCheckboxStates = Object.fromEntries(
        initialValues.svgs.map((svg) => [svg.id, svg.isWrongAnswer || false])
      );
      setSvgCheckboxStates(initialSvgCheckboxStates);
    }
  }, [initialValues?.svgs]);

  const resetAllFields = () => {
    setValues({ inputs: {} });
    setInputCheckboxStates({});
    setSvgCheckboxStates({});
  };

  useImperativeHandle(ref, () => ({
    resetAllFields,
  }));

  const handleInputChangeLocal = (id: number, value: string) => {
    setValues((prev) => ({
      ...prev,
      inputs: {
        ...prev.inputs,
        [id]: {
          ...prev.inputs[id],
          value,
        },
      },
    }));
    onInputChange?.(id, value);
  };

  const handleInputCheckboxChange = (id: number) => {
    setInputCheckboxStates((prev) => {
      const newState = { ...prev, [id]: !prev[id] };

      setValues((prevValues) => ({
        inputs: {
          ...prevValues.inputs,
          [id]: {
            ...prevValues.inputs[id],
            right: newState[id],
          },
        },
      }));

      onCheckboxStateChange?.({ inputs: newState, svgs: svgCheckboxStates });
      return newState;
    });
  };

  const handleSvgCheckboxChange = (id: number) => {
    setSvgCheckboxStates((prev) => {
      const newState = { ...prev, [id]: !prev[id] };

      onCheckboxStateChange?.({ inputs: inputCheckboxStates, svgs: newState });
      return newState;
    });
  };

  return (
    <div
      className={'form148BackContainer'}
      style={{ position: 'relative' }}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <div className={'form148Back-title'}>
        <span>Обратная сторона</span>
      </div>
      {/* SVG Elements */}
      {[...initialValues.svgs || [], ...formDroppedItems].map((svg, index) => (
        <div
          key={`svg-${svg.id}-${index}`}
          style={{
            position: 'absolute',
            left: `${svg.x}px`,
            top: `${svg.y}px`,
            cursor: 'move',
          }}
          draggable={isConstructor}
          onDragStart={() => handleDragStart?.(index, 'response')}
        >
          {svg.type === 'Doctor' && <Doctor />}
          {svg.type === 'MedicalCommission' && <MedicalCommission />}
          {svg.type === 'Recipe' && <Recipe />}
          {svg.type === 'Stamp' && <Stamp />}
          {isShow && (
            <input
              type="checkbox"
              checked={svgCheckboxStates[svg.id] || false}
              style={{ position: 'absolute', top: -10, left: -10 }}
              onChange={() => handleSvgCheckboxChange(svg.id)}
            />
          )}
          {isConstructor && (
            <button
              style={{
                position: 'absolute',
                background: '#ff665e',
                color: 'white',
                border: 'none',
                borderRadius: 4,
                width: 20,
                height: 20,
                top: -10,
                right: -10,
                cursor: 'pointer',
              }}
              onClick={() => onSvgDelete?.(index, svg.id)}
            >
              ×
            </button>
          )}
        </div>
      ))}

      <div className={'form148Back-content-block'}>
        <div className={'form148Back-block'}>
          <span>Отметка о назначении лекарственного препарата по решению врачебной комиссии</span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <input
            id="purpose-input"
            className={'form148Back-input'}
            onChange={(e) => handleInputChangeLocal(1, e.target.value)}
            type="text"
            value={values.inputs?.[1]?.value || ''}
            disabled={isReadOnly}
          />
          {isShow && (
            <input
              id="checkbox-1"
              type="checkbox"
              checked={inputCheckboxStates[1] || false}
              onChange={() => handleInputCheckboxChange(1)}
            />
          )}
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 40 }}>
        {[1, 2, 3].map((id) => (
          <div
            key={`input-${id}`}
            style={{ display: 'flex', flexDirection: 'column', border: '1px solid black', width: 300 }}
          >
            <div style={{ borderBottom: '1px solid black', padding: 15, textAlign: 'center' }}>
              <span>{id === 1 ? 'Приготовил' : id === 2 ? 'Проверил' : 'Отпустил'}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <input
                type="text"
                value={values.inputs?.[id]?.value || ''}
                onChange={(e) => handleInputChangeLocal(id, e.target.value)}
                disabled={isReadOnly}
                style={{ width: '98%', border: 'none' }}
              />
              {isShow && (
                <input
                  type="checkbox"
                  checked={inputCheckboxStates[id] || false}
                  onChange={() => handleInputCheckboxChange(id)}
                  style={{ marginLeft: 10 }}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
})


export default Form148Back