import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import Form148 from '../constructors/taskDescription/components/pharmacy/form148.tsx';
import Form14804 from '../constructors/taskDescription/components/pharmacy/Form148-04.tsx';
import Form14804Back from '../constructors/taskDescription/components/pharmacy/Form148-04Back.tsx';
import Form148Back from '../constructors/taskDescription/components/pharmacy/form148Back.tsx';
import MainLayout from '../../components/Layout/MainLayout';
import NewChatWizardTopMenu from '../startChatWizard/NewChatWizardTopMenu';
import ButtonWithProgress from '../../components/common/ButtonWithProgress';
import chatApi from '../../api/chatApi';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '1280px',
    margin: '0 auto',
    marginTop: 50,
    height: '90vh',
  },
  dropArea: {
    position: 'relative',
    width: '100%',
    minHeight: '600px',
    border: '1px dashed #ccc',
    marginBottom: '20px',
  },
}));

const PharmacyPage = () => {
  const c = useStyles();
  const [task, setTask] = useState<number>(0);
  const [formData, setFormData] = useState<{ [key: number]: any }>({});
  const [formCheckboxStates, setFormCheckboxStates] = useState<{ [key: number]: { inputs: { [key: number]: boolean }; svgs: { [key: number]: boolean } } }>({});
  const [pharmacyList, setPharmacyList] = useState<any[]>([]);

  useEffect(() => {
    const fetchUnfinishedTask = async () => {
      try {
        const response = await chatApi.unfinishedTask();
        setTask(response.result.taskId);
      } catch (err) {
        console.error('Error fetching unfinished task:', err);
      }
    };
    fetchUnfinishedTask();
  }, []);

  useEffect(() => {
    const fetchRecipeTaskDetails = async () => {
      if (task === 0) return;

      try {
        const response = await chatApi.getRecipeTaskDetailByTask(task);
        processResponseArray(response.result);
      } catch (error) {
        console.error('Error fetching recipe task details:', error);
      }
    };

    fetchRecipeTaskDetails();
  }, [task]);

  const processResponseArray = (responseArray: any[]) => {
    const data = responseArray.reduce((acc, item) => {
      const { recipe, recipeDetailsTypesId, id, value, x, y } = item;

      if (!acc[recipe.id]) {
        acc[recipe.id] = { inputs: {}, svgs: [] };
      }

      if (recipeDetailsTypesId === 1) {
        acc[recipe.id].svgs.push({ id, type: value, x, y, isWrongAnswer: false });
      } else {
        acc[recipe.id].inputs[item.cell] = {
          id,
          value,
          right: false,
        };
      }

      return acc;
    }, {});

    setFormData(data);

    const initialCheckboxStates = Object.keys(data).reduce((acc, formId) => {
      const form = data[formId];
      acc[formId] = {
        inputs: Object.keys(form.inputs || {}).reduce((inputAcc, inputId) => {
          inputAcc[inputId] = form.inputs[inputId]?.right || false;
          return inputAcc;
        }, {}),
        svgs: form.svgs.reduce((svgAcc, svg) => {
          svgAcc[svg.id] = svg.isWrongAnswer || false;
          return svgAcc;
        }, {}),
      };
      return acc;
    }, {});
    setFormCheckboxStates(initialCheckboxStates);
  };

  const handleCheckboxStateChange = useCallback((formId, { inputs, svgs }) => {
    setFormData((prev) => ({
      ...prev,
      [formId]: {
        ...prev[formId],
        inputs: {
          ...prev[formId].inputs,
          ...Object.entries(inputs).reduce((acc, [key, value]) => {
            acc[key] = { ...prev[formId].inputs[key], right: value };
            return acc;
          }, {}),
        },
        svgs: prev[formId].svgs.map((svg) => ({
          ...svg,
          isWrongAnswer: svgs[svg.id] ?? svg.isWrongAnswer,
        })),
      },
    }));

    setFormCheckboxStates((prev) => ({
      ...prev,
      [formId]: { inputs, svgs },
    }));
  }, []);

  const renderFormComponent = (formId) => {
    switch (formId) {
      case 4:
        return Form14804;
      case 5:
        return Form14804Back;
      case 6:
        return Form148;
      case 7:
        return Form148Back;
      default:
        return null;
    }
  };

  const handleSave = async () => {
    try {
      const payload = Object.values(formData).flatMap((form) => {
        const inputsPayload = Object.entries(form.inputs || {}).map(([key, input]) => ({
          recipe_taskdetail_id: input.id,
          value: input.right,
        }));

        const svgsPayload = (form.svgs || []).map((svg) => ({
          recipe_taskdetail_id: svg.id,
          value: svg.isWrongAnswer,
        }));

        return [...inputsPayload, ...svgsPayload];
      });

      console.log('Payload to save:', payload);
      const response = await chatApi.createRecipeUserTaskProceed(payload);
      console.log('Save successful:', response);
    } catch (error) {
      console.error('Error while saving:', error);
    }
  };

  useEffect(() => {
    const fetchPharmacyList = async () => {
      try {
        const response = await chatApi.getAllRecipe();
        setPharmacyList(response.result);
      } catch (err) {
        console.error('Error fetching pharmacy list:', err);
      }
    };
  
    fetchPharmacyList();
  }, []);
  

  return (
    <MainLayout desktopHeader={<NewChatWizardTopMenu title="Фармация" />} title="Фармация">
      <Box className={c.root}>
        {pharmacyList.map((form) => {
          const Component = renderFormComponent(form.id);

          if (!Component) return null;

          return (
            <Accordion key={form.id}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{form.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={c.dropArea}>
                  <Component
                    initialValues={formData[form.id] || { inputs: {}, svgs: [] }}
                    formCheckboxStates={formCheckboxStates[form.id] || { inputs: {}, svgs: {} }}
                    isReadOnly={false}
                    isShow={true}
                    onCheckboxStateChange={(checkboxStates) => handleCheckboxStateChange(form.id, checkboxStates)}
                  />
                  <Divider />
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}

        <div style={{ alignSelf: 'center', margin: '10px', marginTop: 50 }}>
          <ButtonWithProgress variant="contained" color="primary" onClick={handleSave}>
            Сохранить
          </ButtonWithProgress>
        </div>
      </Box>
    </MainLayout>
  );
};

export default PharmacyPage;


