import './style.css';
import { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import CustomInput from './CustomInput';
import React from 'react';
import { ReactComponent as Doctor } from '../../../../../assets/img/pharmacy/DOCTOR.svg';
import { ReactComponent as MedicalCommission } from '../../../../../assets/img/pharmacy/medicalCommission.svg';
import { ReactComponent as Recipe } from '../../../../../assets/img/pharmacy/recipe.svg';
import { ReactComponent as Stamp } from '../../../../../assets/img/pharmacy/stamp.svg';

interface Form148Props {
  onInputChange?: (id: number, value: string) => void;
  initialValues: InitialValuesType;
  onCheckboxStateChange?: (inputCheckboxStates: {
    inputs: { [key: number]: boolean };
    svgs: { [key: number]: boolean };
  }) => void;
  isShow?: boolean;
  isReadOnly?: boolean;
  isShowDeleteButton?: boolean;
  formDroppedItems?: SvgType[];
  handleDragStart?: (index: number, type: string) => void;
  handleDragOver?: () => void;
  handleDrop?: (event: React.DragEvent<HTMLDivElement>) => void;
  onSvgDelete?: (index: number, id: number) => void;
  isConstructor?: boolean;
  isChatPage?: boolean;
}
interface InitialValuesType {
  inputs?: {
    [key: number]: {
      value: string | undefined;
      right?: boolean;
      [key: string]: any;
    };
  };
  svgs?: Array<SvgType>;
}

interface SvgType {
  id: number;
  type: string;
  x: number;
  y: number;
  isWrongAnswer: boolean;
}

const Form148 = forwardRef(({
  initialValues,
  onInputChange,
  formDroppedItems = [],
  handleDragStart,
  handleDragOver,
  handleDrop,
  onSvgDelete,
  isShow,
  isReadOnly,
  onCheckboxStateChange,
  isConstructor,
  isChatPage
}: Form148Props, ref) => {
  const [inputCheckboxStates, setInputCheckboxStates] = useState<{ [key: number]: boolean }>({});
  const [svgCheckboxStates, setSvgCheckboxStates] = useState<{ [key: number]: boolean }>({});
  const [values, setValues] = useState(() => ({
    inputs: initialValues?.inputs || {},
  }));

  useEffect(() => {
    setValues((prevValues) => ({
      inputs: {
        ...prevValues.inputs,
        ...initialValues.inputs,
      },
    }));
  }, [initialValues.inputs]);

  useEffect(() => {
    if (initialValues?.inputs) {
      const initialCheckboxStates = Object.fromEntries(
        Object.entries(initialValues.inputs).map(([id, input]) => [
          Number(id),
          input.right || false,
        ])
      );
      setInputCheckboxStates(initialCheckboxStates);
    }
  }, [initialValues?.inputs]);

  useEffect(() => {
    if (initialValues?.svgs) {
      const initialSvgCheckboxStates = Object.fromEntries(
        initialValues.svgs.map((svg) => [svg.id, svg.isWrongAnswer || false])
      );
      setSvgCheckboxStates(initialSvgCheckboxStates);
    }
  }, [initialValues?.svgs]);

  const resetAllFields = () => {
    setValues({ inputs: {} });
    setInputCheckboxStates({});
    setSvgCheckboxStates({});
  };

  useImperativeHandle(ref, () => ({
    resetAllFields,
  }));

  const handleInputChangeLocal = (id: number, value: string) => {
    setValues((prev) => ({
      ...prev,
      inputs: {
        ...prev.inputs,
        [id]: {
          ...prev.inputs[id],
          value,
        },
      },
    }));
    onInputChange?.(id, value);
  };

  const handleInputCheckboxChange = (id: number) => {
    setInputCheckboxStates((prev) => {
      const newState = { ...prev, [id]: !prev[id] };

      setValues((prevValues) => ({
        inputs: {
          ...prevValues.inputs,
          [id]: {
            ...prevValues.inputs[id],
            right: newState[id],
          },
        },
      }));

      onCheckboxStateChange?.({ inputs: newState, svgs: svgCheckboxStates });
      return newState;
    });
  };

  const handleSvgCheckboxChange = (id: number) => {
    setSvgCheckboxStates((prev) => {
      const newState = { ...prev, [id]: !prev[id] };

      onCheckboxStateChange?.({ inputs: inputCheckboxStates, svgs: newState });
      return newState;
    });
  };

  return (
    <div
      className="pharmacyContainer"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <div className="pharmacy-title">
        <span>ФОРМА РЕЦЕПТУРНОГО БЛАНКА N 148-1/У-88</span>
      </div>
      {[...initialValues.svgs || [], ...formDroppedItems].map((svg, index) => (
        <div
          key={`svg-${svg.id}-${index}`}
          style={{
            position: 'absolute',
            left: `${svg.x}px`,
            top: `${svg.y}px`,
            cursor: 'move',
          }}
          draggable={isConstructor}
          onDragStart={() => handleDragStart?.(index, 'response')}
        >
          {svg.type === 'Doctor' && <Doctor />}
          {svg.type === 'MedicalCommission' && <MedicalCommission />}
          {svg.type === 'Recipe' && <Recipe />}
          {svg.type === 'Stamp' && <Stamp />}
          {isShow && (
            <input
              type="checkbox"
              checked={svgCheckboxStates[svg.id] || false}
              style={{ position: 'absolute', top: -10, left: -10 }}
              onChange={() => handleSvgCheckboxChange(svg.id)}
            />
          )}
          {isConstructor && (
            <button
              style={{
                position: 'absolute',
                background: '#ff665e',
                color: 'white',
                border: 'none',
                borderRadius: 4,
                width: 20,
                height: 20,
                top: -10,
                right: -10,
                cursor: 'pointer',
              }}
              onClick={() => onSvgDelete?.(index, svg.id)}
            >
              ×
            </button>
          )}
        </div>
      ))}
      <div className={'pharmacy-input-block'}>
        <span>Серия</span>
        <CustomInput
          id={1}
          onChange={handleInputChangeLocal}
          inputCount={4}
          value={values.inputs?.[1]?.value ?? ''}
          disabled={isReadOnly}
        />
        <span>N</span>
        <CustomInput
          id={2}
          onChange={handleInputChangeLocal}
          inputCount={5}
          value={values.inputs?.[2]?.value ?? ''}
          disabled={isReadOnly}
        />
        {isShow && (
          <input
            id="checkbox-2"
            type="checkbox"
            checked={inputCheckboxStates[2] || false}
            onChange={() => handleInputCheckboxChange(2)}
          />
        )}
      </div>
      <div className={'pharmacy-date-born-block'}>
        <span>РЕЦЕПТ</span>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>"</span>
          <CustomInput
            id={3}
            onChange={handleInputChangeLocal}
            inputCount={2}
            value={values.inputs?.[3]?.value ?? ''}
            disabled={isReadOnly}
          />
          <span>"</span>
          <CustomInput
            id={4}
            onChange={handleInputChangeLocal}
            inputCount={4}
            value={values.inputs?.[4]?.value ?? ''}
            disabled={isReadOnly}
          />
          <span>20</span>
          <CustomInput
            id={5}
            onChange={handleInputChangeLocal}
            inputCount={2}
            value={values.inputs?.[5]?.value ?? ''}
            disabled={isReadOnly}
          />
          <span>г.</span>
          {isShow && (
            <input
              id="checkbox-5"
              type="checkbox"
              checked={inputCheckboxStates[5] || false}
              onChange={() => handleInputCheckboxChange(5)}
            />
          )}
        </div>
      </div>
      <div className={'date-input-description'}>
        <span>(дата оформления рецепта)</span>
      </div>

      <div className={'type-block'}>
        <span style={{ textAlign: 'center' }}>(взрослый, детский - нужное подчеркнуть)</span>
      </div>

      <div className={'fio-block-patient'}>
        <span>Фамилия, инициалы имени и отчества (последнее - при наличии) пациента</span>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <input
            id="fio-patient"
            className={'date-born'}
            onChange={(event) => { handleInputChangeLocal(6, event.target.value); }}
            value={values.inputs?.[6]?.value ?? ''}
            type={'text'}
            disabled={isReadOnly}
          />
          {isShow && (
            <input
              id="checkbox-6"
              type="checkbox"
              checked={inputCheckboxStates[6] || false}
              onChange={() => handleInputCheckboxChange(6)}
            />
          )}
        </div>
      </div>
      {/* Дата рождения */}
      <div className={'pharmacy-date-born-block'}>
        <span>Дата рождения</span>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CustomInput
            id={6}
            onChange={handleInputChangeLocal}
            inputCount={2}
            value={values.inputs?.[6]?.value ?? ''}
            disabled={isReadOnly}
          />
          <CustomInput
            id={7}
            onChange={handleInputChangeLocal}
            inputCount={2}
            value={values.inputs?.[7]?.value ?? ''}
            disabled={isReadOnly}
          />
          <CustomInput
            id={8}
            onChange={handleInputChangeLocal}
            inputCount={4}
            value={values.inputs?.[8]?.value ?? ''}
            disabled={isReadOnly}
          />
          {isShow && (
            <input
              id="checkbox-8"
              type="checkbox"
              checked={inputCheckboxStates[8] || false}
              onChange={() => handleInputCheckboxChange(8)}
            />
          )}
        </div>
      </div>

      {/* Адрес места жительства */}
      <div className={'pharmacy-address-block'} style={{ marginTop: 10 }}>
        <span>
          Адрес места жительства или номер медицинской карты пациента
        </span>
        <input
          type="text"
          value={values.inputs?.[9]?.value ?? ''}
          onChange={(e) => handleInputChangeLocal(9, e.target.value)}
          disabled={isReadOnly}
          className={'address-input'}
          style={{ borderLeft: 'none', borderRight: 'none', borderTop: 'none', width: 630 }}

        />
        {isShow && (
          <input
            id="checkbox-9"
            type="checkbox"
            checked={inputCheckboxStates[9] || false}
            onChange={() => handleInputCheckboxChange(9)}
          />
        )}
      </div>

      {/* Лечащий врач */}
      <div className={'pharmacy-doctor-block'} style={{ marginTop: 10 }}>
        <span>Фамилия и инициалы лечащего врача</span>
        <input
          type="text"
          value={values.inputs?.[10]?.value ?? ''}
          onChange={(e) => handleInputChangeLocal(10, e.target.value)}
          disabled={isReadOnly}
          className={'doctor-input'}
          style={{ borderLeft: 'none', borderRight: 'none', borderTop: 'none', width: 330 }}
        />
        {isShow && (
          <input
            id="checkbox-10"
            type="checkbox"
            checked={inputCheckboxStates[10] || false}
            onChange={() => handleInputCheckboxChange(10)}
          />
        )}
      </div>

      {/* Поле для рецепта */}
      <div className={'pharmacy-rp-block'} style={{ marginTop: 10 }}>
        <span>Rp.</span>
        <input
          value={values.inputs?.[11]?.value ?? ''}
          onChange={(e) => handleInputChangeLocal(11, e.target.value)}
          disabled={isReadOnly}
          style={{
            borderLeft: 'none',
            borderTop: 'none',
            borderRight: 'none',
            borderBottom: '1px dotted black',
            width: '90%',
          }}
        />
        {isShow && (
          <input
            id="checkbox-11"
            type="checkbox"
            checked={inputCheckboxStates[11] || false}
            onChange={() => handleInputCheckboxChange(11)}
          />
        )}
      </div>

      {/* Подписи */}
      <div className={'pharmacy-signatures-block'} style={{ marginTop: 10 }}>
        <span>Подпись и печать лечащего врача</span>
        <input
          type="text"
          value={values.inputs?.[12]?.value ?? ''}
          onChange={(e) => handleInputChangeLocal(12, e.target.value)}
          disabled={isReadOnly}
          style={{
            borderLeft: 'none',
            borderTop: 'none',
            borderRight: 'none',
            borderBottom: '1px dotted black',
            width: '90%'
          }}
        />
        {isShow && (
          <input
            id="checkbox-12"
            type="checkbox"
            checked={inputCheckboxStates[12] || false}
            onChange={() => handleInputCheckboxChange(12)}
          />
        )}
      </div>

      {/* Дополнительная информация */}
      <div className={'pharmacy-extra-block'}>
        <span>Дополнительная информация</span>
        <input
          value={values.inputs?.[13]?.value ?? ''}
          onChange={(e) => handleInputChangeLocal(13, e.target.value)}
          disabled={isReadOnly}
          style={{
            borderLeft: 'none',
            borderTop: 'none',
            borderRight: 'none',
            borderBottom: '1px dotted black',
            width: '90%',
          }}
        />
        {isShow && (
          <input
            id="checkbox-13"
            type="checkbox"
            checked={inputCheckboxStates[13] || false}
            onChange={() => handleInputCheckboxChange(13)}
          />
        )}
      </div>

      <div className={'caption-block'}>
        <div className={'pharmacy-signatures-block'}>
          <span>Подпись и печать лечащего врача</span>
          <input
            value={values.inputs?.[14]?.value ?? ''}
            onChange={(e) => handleInputChangeLocal(14, e.target.value)}
            disabled={isReadOnly}
            style={{
              borderLeft: 'none',
              borderTop: 'none',
              borderRight: 'none',
              borderBottom: '1px dotted black',
              width: '90%',
            }}
          />
          {isShow && (
            <input
              id="checkbox-13"
              type="checkbox"
              checked={inputCheckboxStates[14] || false}
              onChange={() => handleInputCheckboxChange(14)}
            />
          )}
          <span>(подпись фельдшера, акушерки)</span>
        </div>
      </div>
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <span>М.П.</span>
      </div>

      <div className={'valid-block'}>
        <span>Рецепт действителен в течение 15 дней</span>
      </div>
    </div>
  );
})

export default Form148